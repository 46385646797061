import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import "./AdminChat.css"

const AdminChat = () => {

    const divRef = useRef(null);

    const adminChatAccountInfo = JSON.parse(localStorage.getItem("adminChatAccountInfo"));
    if (adminChatAccountInfo) {
        var userID = adminChatAccountInfo?._id;
        var token = adminChatAccountInfo?.token
        var userName = adminChatAccountInfo?.name
    }

    //config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const navigate = useNavigate()

    const [users, setUsers] = useState([])
    const [activeUser, setActiveUser] = useState(null)
    const [message, setMessage] = useState("")
    const [newMessage, setNewMessage] = useState()
    const [chats, setChats] = useState([])

    const handleChange = (e) => {
        setMessage(e.target.value)
    }

    const varifyChatControler = async () => {
        try {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/varify-chat-account`, config)
        } catch (error) {
            navigate("/login")
        }
    }

    const getAllSuport = async () => {
        try {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/get-all-suport`, config)
            setUsers(data?.allsuport)


            if (activeUser) {
                const filteredData = data?.allsuport?.filter(item => item.userID === activeUser?.userID);
                console.log("filteredData", filteredData)
                setChats(filteredData[0].messages)
            }
        } catch (error) {
            console.log("error", error)

        }
    }

    const handleChangeUser = (item) => {
        setActiveUser(item)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (message !== "") {
            try {
                const { data } = await axios.post("https://app.timetraker.com/backend/api/create-new-responce", {
                    user: activeUser,
                    message: message
                },
                    config)

                if (data) {
                    setNewMessage(data?.createdNewResponce)
                    setMessage("")
                }
            } catch (error) {
                console.log("error", error)
            }
        } else {
            alert("Please write some content.")
        }
    }

    useEffect(() => {
        varifyChatControler()
        getAllSuport()
    }, [])

    useEffect(() => {
        getAllSuport()
    }, [newMessage])

    useEffect(() => {
        setChats(activeUser?.messages)
    }, [activeUser])

    // useEffect(() => {
    //     users.map((element, index) => {

    //         const filteredData = element?.messages?.filter(item => item.readbyadmin === 1);

    //         console.log("filteredData", filteredData)
    //         if (filteredData?.length> 0) {
    //             users[index].readbyadmin = 1
    //         }
    //     })

    //     console.log("usersusers", users)
    // }, [users])


    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollTop = divRef.current.scrollHeight;
        }
    }, [chats]);
    return (
        <div>
            <div id="container">
                <aside>
                    <header>
                        <input type="text" placeholder="search" />
                    </header>
                    <ul>
                        {users?.map((item, index) => {
                            return (
                                <li onClick={() => { handleChangeUser(item) }} style={{ marginTop: "10px" }} key={index}>
                                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt="" />
                                    <div>
                                        <h2>{item?.userName}</h2>
                                        {item.readbyadmin === 1 ?
                                            <span style={{ background: "red" }} className="status green"></span>
                                            : null}
                                    </div>
                                </li>
                            )
                        })}

                    </ul>
                </aside>
                <main>
                    {activeUser ?
                        <div>
                            <header>
                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt="" />
                                <div>
                                    <h2>Chat with {activeUser?.userName}</h2>
                                    <h3>already {activeUser?.messages?.length} messages</h3>
                                </div>
                            </header>
                            <ul ref={divRef} id="chat">


                                {chats?.map((item, index) => {
                                    console.log("chats", chats)
                                    return (
                                        <li key={index} className={item?.status === "send" ? "you" : "me"}>
                                            <div style={item?.status === "send" ? { justifyContent: "start" } : { justifyContent: "end" }} className="entete">
                                                <div>
                                                    <h2>{item?.status === "send" ? activeUser?.userName : "me"}</h2>
                                                    <span className="status green"></span>
                                                </div>
                                                <h3>{item?.time}, {item?.date}</h3>
                                            </div>
                                            <div className="triangle"></div>
                                            <div className="message">
                                                {item?.content}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                            <footer>
                                <textarea value={message} onChange={handleChange} placeholder="Type your message"></textarea>
                                <button onClick={handleSubmit}  >send</button>
                            </footer>
                        </div>
                        : null}
                </main>
            </div>
        </div>
    )
}

export default AdminChat



