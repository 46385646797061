import React, { useState, useEffect, useContext } from "react";
import "../Quickbooks.css";
import axios from "axios";
import { MyContext } from "../../../context/MyProvider";
import AdminLayout from "../../../hoc/AdminLayout";
import { Col, Container, Row } from "react-bootstrap";
import Loder from "../../../components/Loder";
import ProfileComponent from "../../../components/ProfileComponent";
import { ToastContainer, toast } from 'react-toastify';

const Hubspot = () => {



    const [loading, setLoading] = useState(false)



    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var TTtoken = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var TTtoken = userLoginInfo?.token
    }


    var config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${TTtoken}`
        },
    };

    const [connected, setConnected] = useState(false)



    const authorizeUriFn = () => {
        window.location.href = `https://app.timetraker.com/backend/api/hubspot/authUri/?companyID=${companyID}`;
    }

    async function getEmployee(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://app.timetraker.com/backend/api/hubspot/employee-async/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with HubSpot.")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with HubSpot and try again");
        }
    }

    async function getCustomer(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://app.timetraker.com/backend/api/hubspot/project-async/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with HubSpot.")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with HubSpot and try again");
        }
    }

    async function downloadEmployee(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://app.timetraker.com/backend/api/hubspot/employee-download/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with HubSpot.")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with HubSpot and try again");
        }
    }

    async function downloadProject(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://app.timetraker.com/backend/api/hubspot/project-download/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with HubSpot.")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with HubSpot and try again");
        }
    }

    async function downloadTimesheet(e) {
        setLoading(true)
        try {
            let { data } = await axios.get(
                `https://app.timetraker.com/backend/api/zoho/timesheet-download/?companyID=${companyID}`,
                config
            );
            setLoading(false)
            if (data.connectionError) {
                errorNotify("Please connect with HubSpot.")
            }
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with HubSpot and try again");
        }
    }



    async function refreshToken() {

        setLoading(true)
        try {
            let resp = await axios.get(
                `https://app.timetraker.com/backend/api/hubspot/refresh/?companyID=${companyID}`,
                config
            );
            setConnected(true)
            setLoading(false)
        } catch (error) {
            setConnected(false)
            setLoading(false)

        }
    }

    async function disconnect(e) {

        setLoading(true)
        try {
            let resp = await axios.get(
                `https://app.timetraker.com/backend/api/hubspot/disconnect/?companyID=${companyID}`,
                config
            );
            setConnected(false)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            errorNotify("There is some error please connect with HubSpot and try again");
        }
    }



    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    useEffect(() => {
        refreshToken()
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const referValue = params.get('error');

        if (referValue) {
            errorNotify("Something went wrong, Please try agin.")
        }

    }, []);

    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <AdminLayout>
            <ToastContainer />
            {
                loading ?
                    <Loder className='loder' />
                    :
                    console.log("")
            }
            <div
                // style={loading ? { filter: 'blur(2px)' } : console.log('')}
                className={sideBarStatus ? "content_right_dashboard_quick" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : loading ?
                            { filter: 'blur(2px)', display: "block", minHeight: "100vh", background: "#f1f1f1" }
                            : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                }
            >

                <Container style={{ padding: "0" }} fluid className="dash3" >
                    {/* Row 1 */}
                    <Row>
                        <Col className="task-container">
                            <div
                                className="hamburgar"
                                style={
                                    sideBarStatus ? { display: "none" } : { display: "block" }
                                }
                            >
                                <i onClick={handleClick} className="fas fa-bars"></i>
                            </div>
                            <div className="task-top">
                                <div
                                    className="task-header1"

                                >
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        HubSpot
                                    </h5>
                                </div>

                                <div className="profile-header2">

                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div style={{ padding: "15px 30px" }}>



                    <p className="text-center mt-5">

                        {!connected ?
                            <p className="text-center mt-5">
                                Now click the <b>Connect to HubSpot</b> button below.
                            </p>
                            :
                            <p className="text-center mt-5">
                                Click on the <b>Disconnect</b> button to disconnect from HubSpot.
                            </p>
                        }
                    </p>

                    <div className="display-box">
                        {!connected ?
                            <button
                                style={{
                                    color: "white",
                                    border: "2px solid #0277BD",
                                    fontSize: "20px",
                                    background: "#0277BD",
                                    width: "217px",
                                    height: "45px"
                                }}
                                onClick={authorizeUriFn}>
                                Connect To HubSpot
                            </button>
                            :
                            <button
                                onClick={disconnect}
                                style={{
                                    color: "#0277BD",
                                    border: "2px solid #0277BD",
                                    fontSize: "20px",
                                    background: "white",
                                    width: "217px",
                                    height: "45px"
                                }}
                            >
                                Disconnect
                            </button>
                        }
                    </div>


                    <div className="headline">
                        <h1>Integrate with HubSpot</h1>
                    </div>


                    <div className="api-button">
                        <h2>Sync and Download required data </h2>
                        <div className="api-button-container">

                            <div className="sync_button">

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={getEmployee}
                                >
                                    Sync Employee
                                </button>

                               <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={getCustomer}
                                >
                                    Sync Project
                                </button> 


                            </div>

                            <div className="download_button">
                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={downloadEmployee}
                                >
                                    Download Employee
                                </button>
                              

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={downloadProject}
                                >
                                    Download Project
                                </button>

  {/* 

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={downloadTimesheet}
                                >
                                    Download Time Logs
                                </button>

                                <button
                                    className="btn btn-success btn_qb_2"
                                    onClick={handleClickOpen}
                                >
                                    Create Timesheet
                                </button> */}



                            </div>

                        </div>


                    </div>
                </div>



                <div className="more-info">
                    <hr />
                    <p>More Info:</p>
                    <ul>
                        <li>
                            <a href="#">
                                Integrate With HubSpot Guide
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Sync And Download Gide
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                About TimeTraker
                            </a>
                        </li>
                    </ul>
                </div>
                {/* <hr /> */}
                <p className="text-center text-muted">
                    &copy; 2024 TimeTraker&trade;, All rights reserved. TimeTraker and
                    FCIT are registered trademarks of TimeTraker.
                </p>

            </div>

        </AdminLayout >
    );
};

export default Hubspot;
