import React, { useContext, useState } from 'react'
import AdminLayout from '../../hoc/AdminLayout'
import "./Task.css";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import axios from "axios";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Loder from '../../components/Loder';
import "./Quickbooks.css"
import ProfileComponent from '../../components/ProfileComponent';

const Bamboohr = () => {

    const navigate = useNavigate();

    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }

    const config = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const [formData, setFromData] = useState({ companyID: companyID });
    const [fromTimesheetDuration, setFromTimesheetDuration] = useState({});
    const [loading, setLoading] = useState(false)

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

   async function authorizeUri() {


       await axios
            .get(
                `https://app.timetraker.com/backend/bamboo-auth/?subdomain=${formData.subDomain}&companyID=${companyID}`
            )
            .then(function (authUrl) {

                var parameters = "location=1,width=800,height=650";

                var win = window.open(authUrl.data, "connectPopup", parameters);

            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if ( formData.subDomain === "") {
            alert("Please fill all the fields")
        } else {
            authorizeUri()
           
        }
    };

    const getEmployee = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-employee-bamboo-to-timetraker/?companyID=${companyID}`,
                config
            );
            if (data) {
                setLoading(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Something went wrong.")
        }
    }
    const getProject = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-project-bamboo-to-timetraker/?companyID=${companyID}`,
                config
            );
            if (data) {
                setLoading(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Something went wrong.")
        }
    }

    const downloadEmployee = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-employee-timetraker-to-bamboo/?companyID=${companyID}`,
                config
            );
            if (data) {
                setLoading(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Something went wrong.")
        }
    }

    const downloadProject = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-project-timetraker-to-bamboo/?companyID=${companyID}`,
                config
            );
            if (data) {
                setLoading(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Something went wrong.")
        }
    }


    const downloadTimesheet = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-timesheet-timetraker-to-bamboo/?companyID=${companyID}`,
                config
            );
            if (data) {
                setLoading(false)
            }
        } catch (error) {
            console.log("error", error)
            alert("Something went wrong.")
        }
    }



    const handleChangeTimesheetDate = (e) => {
        const { name, value } = e.target;
        setFromTimesheetDuration((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    return (
        <div>
            {
                loading ?
                    <Loder className='loder' />
                    :
                    console.log("")
            }
            <AdminLayout>

                <div
                    className={sideBarStatus ? "content_right_dashboard_2" : "none"}
                    fluid
                    style={
                        (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                            ? { display: "none" }
                            : loading ?
                                { filter: 'blur(2px)', display: "block", minHeight: "100vh", background: "#f1f1f1" }
                                : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                    }
                >
                    <Container fluid className="dash3" style={{ padding: "0" }}>
                        {/* Row 1 */}
                        <Row>
                            <Col className="task-container">
                                <div
                                    className="hamburgar"
                                    style={
                                        sideBarStatus ? { display: "none" } : { display: "block" }
                                    }
                                >
                                    <i onClick={handleClick} className="fas fa-bars"></i>
                                </div>
                                <div className="task-top">
                                    <div className="task-header1">
                                        <h5
                                            style={
                                                sideBarStatus == true
                                                    ? { paddingLeft: "10px", paddingTop: "2px" }
                                                    : { paddingLeft: "60px", paddingTop: "4px" }
                                            }
                                        >
                                            BambooHR
                                        </h5>
                                    </div>

                                    <div className="task-header2">
                                    <div className="profile-header2">
                                    <ProfileComponent />
                                </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="headline">
                            <h1>Integrate with BambooHR</h1>
                        </div>

                        <Row style={{ background: "#F1F1F1", padding: "0px 50px" }}>
                            <Col md={12}>
                                <div className="adduser peoject_form_padding" style={{ marginBottom: "47px" }}>
                                    <div className="adduser-header project-header">
                                        <button style={{width: "170px"}} className="btn1">BambooHR Info</button>
                                    </div>


                                    <form
                                        onSubmit={handleSubmit}
                                        action=""
                                        className="adduser-form"
                                        style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                                    >

                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <div className="form1 ">
                                                <div className="mb-5 projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Sub-Domain
                                                    </label>
                                                    <input
                                                        required
                                                        value={formData.subDomain}
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="subDomain"
                                                        id=""
                                                    />
                                                </div>


                                            </div>

                                            <div className="form2">

                                                <div  className="role-border projectInput">
                                                    <div className="adduserBtn connect-with-bamboohr">
                                                        <button style={{marginBottom: "27px"}} type="submit" className="btn5">
                                                            connect
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </Col>
                        </Row>




                        <div style={{ padding: "50px" }}>
                            <div className="api-button">
                                <h2>Sync and Download required data </h2>
                                <div className="api-button-container">

                                    <div className="sync_button">

                                        <button
                                            className="btn btn-success btn_qb_2"
                                            onClick={getEmployee}
                                        >
                                            Sync Employee
                                        </button>

                                        <button
                                            className="btn btn-success btn_qb_2"
                                            onClick={getProject}
                                        >
                                            Sync project
                                        </button>

                                    </div>



                                    <div className="download_button">
                                        <button
                                            className="btn btn-success btn_qb_2"
                                            onClick={downloadEmployee}
                                        >
                                            Download Employee
                                        </button>
                                        <button
                                            className="btn btn-success btn_qb_2"
                                            onClick={downloadProject}
                                        >
                                            Download Project
                                        </button>
                                        <button
                                            className="btn btn-success btn_qb_2"
                                            onClick={downloadTimesheet}
                                        >
                                            Download Timesheet
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Container>
                </div>








            </AdminLayout>
        </div>
    )
}

export default Bamboohr
