import React, { useState, useEffect, useContext } from "react";
import AdminLayout from "../../hoc/AdminLayout";
import { useNavigate } from "react-router-dom";
import './AdminWeeklyTimesheet.css'
// import { Col, Container, Row } from "react-bootstrap";
import signupImg1 from "../../img/Vector.png";
import signupImg2 from "../../img/user2.png";
import axios from "axios";
import Form from "react-bootstrap/Form";
import { Col, Container, Row } from "react-bootstrap";
import UserTimeSheetTable from "../../components/UserTimeSheetTable";
import { MyContext } from '../../context/MyProvider'
import UserPdf from "./UserPdf";
import UserExcel from "./UserExcel";
import UserCsv from "./UserCsv";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import Swal from "sweetalert2";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const AdminWeeklyTimesheet = () => {






  const { sideBarStatus, setSideBarStatus, additionalSettings } = useContext(MyContext);

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false)
    } else {
      setSideBarStatus(true)
    }
  }


  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true
  } else {
    var forPhoneScreenNoDisplay = false
  }




  const [newTimesheetData, setNewTimesheetData] = useState();
  const [userTimesheetInfo, setUserTimesheetInfo] = useState([]);

  const getThisWeekDates = () => {
    const today = new Date();
    const currDate = today.getDate();
    const currDay = today.getDay();

    console.log("currDate", currDate)
    console.log("currDay", currDay)

    // Calculate the start date of the week (Sunday)
    const startDate = new Date(today);
    startDate.setDate(currDate - currDay + 1);

    const dates = [];

    // Push the dates of the week into the 'dates' array
    for (let i = 0; i < 7; i++) {
      const tempDate = new Date(startDate);
      tempDate.setDate(startDate.getDate() + i);
      dates.push(tempDate.toDateString()); // You can format this date as needed
    }


    return dates;
  };


  const [thisWeekDates, setThisWeekDates] = useState(getThisWeekDates())
  const [projectInfo, setProjectInfo] = useState([]);
  const [classesInfo, setClassesInfo] = useState([]);
  const [weeklytimesheetData, setWeeklytimesheetData] = useState([]);
  const [taskInfo, setTaskInfo] = useState([]);
  const [policy, setPolicy] = useState([]);
  const [project1, setProject1] = useState("");
  const [project2, setProject2] = useState("");
  const [project3, setProject3] = useState("");
  const [project4, setProject4] = useState("");
  const [project5, setProject5] = useState("");
  const [project6, setProject6] = useState("");
  const [project7, setProject7] = useState("");
  const [project8, setProject8] = useState("");
  const [project9, setProject9] = useState("");
  const [project10, setProject10] = useState("");
  const [project11, setProject11] = useState("");
  const [project12, setProject12] = useState("");
  const [project13, setProject13] = useState("");
  const [isProjectBillable1, setIsProjectBillable1] = useState(false);
  const [isProjectBillable2, setIsProjectBillable2] = useState(false);
  const [isProjectBillable3, setIsProjectBillable3] = useState(false);
  const [isProjectBillable4, setIsProjectBillable4] = useState(false);
  const [isProjectBillable5, setIsProjectBillable5] = useState(false);
  const [isProjectBillable6, setIsProjectBillable6] = useState(false);
  const [isProjectBillable7, setIsProjectBillable7] = useState(false);
  const [isProjectBillable8, setIsProjectBillable8] = useState(false);
  const [isProjectBillable9, setIsProjectBillable9] = useState(false);
  const [isProjectBillable10, setIsProjectBillable10] = useState(false);
  const [isProjectBillable11, setIsProjectBillable11] = useState(false);
  const [isProjectBillable12, setIsProjectBillable12] = useState(false);
  const [isProjectBillable13, setIsProjectBillable13] = useState(false);
  const [isTaskBillable1, setIsTaskBillable1] = useState(false);
  const [isTaskBillable2, setIsTaskBillable2] = useState(false);
  const [isTaskBillable3, setIsTaskBillable3] = useState(false);
  const [isTaskBillable4, setIsTaskBillable4] = useState(false);
  const [isTaskBillable5, setIsTaskBillable5] = useState(false);
  const [isTaskBillable6, setIsTaskBillable6] = useState(false);
  const [isTaskBillable7, setIsTaskBillable7] = useState(false);
  const [isTaskBillable8, setIsTaskBillable8] = useState(false);
  const [isTaskBillable9, setIsTaskBillable9] = useState(false);
  const [isTaskBillable10, setIsTaskBillable10] = useState(false);
  const [isTaskBillable11, setIsTaskBillable11] = useState(false);
  const [isTaskBillable12, setIsTaskBillable12] = useState(false);
  const [isTaskBillable13, setIsTaskBillable13] = useState(false);
  const [isBillable1, setIsBillable1] = useState(true);
  const [isBillable2, setIsBillable2] = useState(true);
  const [isBillable3, setIsBillable3] = useState(true);
  const [isBillable4, setIsBillable4] = useState(true);
  const [isBillable5, setIsBillable5] = useState(true);
  const [isBillable6, setIsBillable6] = useState(true);
  const [isBillable7, setIsBillable7] = useState(true);
  const [isBillable8, setIsBillable8] = useState(true);
  const [isBillable9, setIsBillable9] = useState(true);
  const [isBillable10, setIsBillable10] = useState(true);
  const [isBillable11, setIsBillable11] = useState(true);
  const [isBillable12, setIsBillable12] = useState(true);
  const [isBillable13, setIsBillable13] = useState(true);
  const [task1, setTask1] = useState("");
  const [task2, setTask2] = useState("");
  const [task3, setTask3] = useState("");
  const [task4, setTask4] = useState("");
  const [task5, setTask5] = useState("");
  const [task6, setTask6] = useState("");
  const [task7, setTask7] = useState("");
  const [task8, setTask8] = useState("");
  const [task9, setTask9] = useState("");
  const [task10, setTask10] = useState("");
  const [task11, setTask11] = useState("");
  const [task12, setTask12] = useState("");
  const [task13, setTask13] = useState("");
  const [reload, setReload] = useState(false);



  console.log("task3", task3)





  const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

  if (adminloginInfo) {
    var companyID = adminloginInfo?._id;
    var token = adminloginInfo?.token
    var userID = adminloginInfo._id;
    var userName = adminloginInfo.name;
    var TimesheetLocationTrack = adminloginInfo.accessibility.TimesheetLocationTrack;
  }
  if (userLoginInfo) {
    var companyID = userLoginInfo?.companyID;
    var token = userLoginInfo?.token
    var userID = userLoginInfo._id;
    var userName = userLoginInfo.name;
    var TimesheetLocationTrack = userLoginInfo.accessibility.TimesheetLocationTrack;
  }


  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };
  const getAllProject = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/user-project/?userID=${userID}`,
        config
      );
      setProjectInfo(data.projects);
    } catch (error) {
      console.log('error', error)
    }
  };


  const getAllClasses = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-active-classes/?companyID=${companyID}`,
        config
      );
      setClassesInfo(data.classes);
    } catch (error) {
      console.log('error', error)
    }
  };

  const getAllTask = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-task/?companyID=${companyID}`,
        config
      );
      setTaskInfo(data.tasks);
    } catch (error) {
      console.log('error', error)
    }
  };

  const getUserPolciy = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/get-policy-for-users/?userID=${userID}`,
        config
      );
      if (data) {
        setPolicy(data.allPolicy)
      }
    } catch (error) {
      console.log("error from get user timesheet api ", error);
    }
  };


  const [prviousCount, setPreviousCount] = useState(7)

  const getPreviousThisWeekDates = () => {
    // const today = new Date();
    // const currDate = today.getDate();
    // const currDay = today.getDay();

    // // Calculate the start date of the week (Sunday)
    // const startDate = new Date(today);
    // startDate.setDate(currDate - currDay);

    // const dates = [];

    // // Push the dates of the week into the 'dates' array
    // for (let i = 7; i > 0; i--) {
    //   const tempDate = new Date(startDate);
    //   tempDate.setDate(startDate.getDate() - i);
    //   dates.push(tempDate.toDateString()); // You can format this date as needed
    // }

    // return dates;

    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = today.getDate() - dayOfWeek;

    const lastSunday = new Date(today);
    lastSunday.setDate(diff - prviousCount + 1);
    setPreviousCount(prviousCount + 7)

    const dates = [];

    for (let i = 0; i < 7; i++) {
      const tempDate = new Date(lastSunday);
      tempDate.setDate(lastSunday.getDate() + i);
      dates.push(tempDate.toDateString()); // You can format this date as needed
    }


    return dates;
  };

  const getNextWeekDates = () => {
    // const today = new Date();
    // const currDate = today.getDate();
    // const currDay = today.getDay();

    // // Calculate the start date of the week (Sunday)
    // const startDate = new Date(today);
    // startDate.setDate(currDate - currDay);

    // const dates = [];

    // // Push the dates of the week into the 'dates' array
    // for (let i = 7; i < 14; i++) {
    //   const tempDate = new Date(startDate);
    //   tempDate.setDate(startDate.getDate() + i);
    //   dates.push(tempDate.toDateString()); // You can format this date as needed
    // }

    // return dates;

    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = today.getDate() - dayOfWeek;

    const lastSunday = new Date(today);
    lastSunday.setDate(diff - prviousCount + 14 + 1);
    setPreviousCount(prviousCount - 7)

    const dates = [];

    for (let i = 0; i < 7; i++) {
      const tempDate = new Date(lastSunday);
      tempDate.setDate(lastSunday.getDate() + i);
      dates.push(tempDate.toDateString()); // You can format this date as needed
    }


    return dates;

  };



  const prevWeekTimesheet = () => {
    setThisWeekDates(getPreviousThisWeekDates())

  }
  const currentWeekTimesheet = () => {
    window.location.reload()
  }
  const nextWeekTimesheet = () => {
    setThisWeekDates(getNextWeekDates())
  }


  // const firstChange = {};

  // const firstChangeInfo = JSON.parse(localStorage.getItem('firstChange'));
  // console.log(firstChangeInfo)

  const handleChange = async (e) => {

    if (e.target.dataset.task == "") {
      alert("Can't save this Timesheet. Please selact task");
    } else {
      const dateID = e.target.dataset.id;
      const project = e.target.dataset.project;
      const task = e.target.dataset.task;
      const name = e.target.name;
      const hours = e.target.value;
      const billable = e.target.dataset.billable
      let timeOff = false
      if (project == "") {
        timeOff = true
      }

      // convert the date

      const convertDate = (dateString) => {
        const months = {
          Jan: "01",
          Feb: "02",
          Mar: "03",
          Apr: "04",
          May: "05",
          Jun: "06",
          Jul: "07",
          Aug: "08",
          Sep: "09",
          Oct: "10",
          Nov: "11",
          Dec: "12",
        };

        const dateParts = dateString.split(" ");
        const year = dateParts[3];
        const month = months[dateParts[1]];
        const day = dateParts[2];

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      };

      if (dateID) {
        var date = convertDate(dateID);
      }
      try {
        const { data } = await axios.post(
          `https://app.timetraker.com/backend/api/create-timesheet-entry`,
          { date, name, project, task, hours, userID, companyID, userName, billable, location, timeOff },
          config
        );
        if (data.locationNull) {
          errorNotify("Please enable location access.")
          return
        }
        if (data.TimeOffError) {
          errorNotify("You can't request for Time Off right now.")
          return
        }
        if (data.availableTimeOffError) {
          errorNotify("You don't have sufficient time off.")
          return
        }
        if (data.holidayError) {
          errorNotify("You can't request for Time Off in holiday")
          return
        }
        if (data.AnotherLocation) {
          errorNotify("You are not in the expected location!")
          return
        }
        if (data) {
          setReload(true);
          e.target.blur();
          document.getElementById("resetForm").reset()
          setNewTimesheetData(data);
          successNotify("Your timesheet has been added");
        }

      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeProject1 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject1(e.target.value);
    setIsProjectBillable1(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject2 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject2(e.target.value);
    setIsProjectBillable2(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject3 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject3(e.target.value);
    setIsProjectBillable3(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject4 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject4(e.target.value);
    setIsProjectBillable4(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject5 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject5(e.target.value);
    setIsProjectBillable5(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject6 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject6(e.target.value);
    setIsProjectBillable6(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject7 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject7(e.target.value);
    setIsProjectBillable7(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject8 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject8(e.target.value);
    setIsProjectBillable8(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject9 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject9(e.target.value);
    setIsProjectBillable9(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject10 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject10(e.target.value);
    setIsProjectBillable10(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject11 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject11(e.target.value);
    setIsProjectBillable11(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject12 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject12(e.target.value);
    setIsProjectBillable12(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeProject13 = (e) => {
    const data = projectInfo?.filter(item => item.project == e.target.value)
    setProject13(e.target.value);
    setIsProjectBillable13(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };

  const handleChangeBillable1 = (e) => {
    setIsBillable1((current) => !current);
  };
  const handleChangeBillable2 = (e) => {
    setIsBillable2((current) => !current);
  };
  const handleChangeBillable3 = (e) => {
    setIsBillable3((current) => !current);
  };
  const handleChangeBillable4 = (e) => {
    setIsBillable4((current) => !current);
  };
  const handleChangeBillable5 = (e) => {
    setIsBillable5((current) => !current);
  };
  const handleChangeBillable6 = (e) => {
    setIsBillable6((current) => !current);
  };
  const handleChangeBillable7 = (e) => {
    setIsBillable7((current) => !current);
  };
  const handleChangeBillable8 = (e) => {
    setIsBillable8((current) => !current);
  };
  const handleChangeBillable9 = (e) => {
    setIsBillable9((current) => !current);
  };
  const handleChangeBillable10 = (e) => {
    setIsBillable10((current) => !current);
  };
  const handleChangeBillable11 = (e) => {
    setIsBillable11((current) => !current);
  };
  const handleChangeBillable12 = (e) => {
    setIsBillable12((current) => !current);
  };
  const handleChangeBillable13 = (e) => {
    setIsBillable13((current) => !current);
  };


  const handleChangeTask1 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask1(e.target.value);
    setIsTaskBillable1(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask2 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask2(e.target.value);
    setIsTaskBillable2(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask3 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask3(e.target.value);
    setIsTaskBillable3(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask4 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask4(e.target.value);
    setIsTaskBillable4(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask5 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask5(e.target.value);
    setIsTaskBillable5(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask6 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask6(e.target.value);
    setIsTaskBillable6(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask7 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask7(e.target.value);
    setIsTaskBillable7(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask8 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask8(e.target.value);
    setIsTaskBillable8(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask9 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask9(e.target.value);
    setIsTaskBillable9(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask10 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask10(e.target.value);
    setIsTaskBillable10(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask11 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask11(e.target.value);
    setIsTaskBillable11(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask12 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask12(e.target.value);
    setIsTaskBillable12(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };
  const handleChangeTask13 = (e) => {
    const data = taskInfo?.filter(item => item.task == e.target.value)
    setTask13(e.target.value);
    setIsTaskBillable13(data?.[0]?.billable == true || data?.[0]?.billable == false ? data?.[0]?.billable : false)
  };

  useEffect(() => {
    if (isProjectBillable1 === true || isTaskBillable1 === true) {
      setIsBillable1(true)
    } else {
      setIsBillable1(false)
    }
  }, [isProjectBillable1, isTaskBillable1])

  useEffect(() => {
    if (isProjectBillable2 === true || isTaskBillable2 === true) {
      setIsBillable2(true)
    } else {
      setIsBillable2(false)
    }
  }, [isProjectBillable2, isTaskBillable2])

  useEffect(() => {
    if (isProjectBillable3 === true || isTaskBillable3 === true) {
      setIsBillable3(true)
    } else {
      setIsBillable3(false)
    }
  }, [isProjectBillable3, isTaskBillable3])

  useEffect(() => {
    if (isProjectBillable4 === true || isTaskBillable4 === true) {
      setIsBillable4(true)
    } else {
      setIsBillable4(false)
    }
  }, [isProjectBillable4, isTaskBillable4])

  useEffect(() => {
    if (isProjectBillable5 === true || isTaskBillable5 === true) {
      setIsBillable5(true)
    } else {
      setIsBillable5(false)
    }
  }, [isProjectBillable5, isTaskBillable5])

  useEffect(() => {
    if (isProjectBillable6 === true || isTaskBillable6 === true) {
      setIsBillable6(true)
    } else {
      setIsBillable6(false)
    }
  }, [isProjectBillable6, isTaskBillable6])

  useEffect(() => {
    if (isProjectBillable7 === true || isTaskBillable7 === true) {
      setIsBillable7(true)
    } else {
      setIsBillable7(false)
    }
  }, [isProjectBillable7, isTaskBillable7])

  useEffect(() => {
    if (isProjectBillable8 === true || isTaskBillable8 === true) {
      setIsBillable8(true)
    } else {
      setIsBillable8(false)
    }
  }, [isProjectBillable8, isTaskBillable8])

  useEffect(() => {
    if (isProjectBillable9 === true || isTaskBillable9 === true) {
      setIsBillable9(true)
    } else {
      setIsBillable9(false)
    }
  }, [isProjectBillable9, isTaskBillable9])

  useEffect(() => {
    if (isProjectBillable10 === true || isTaskBillable10 === true) {
      setIsBillable10(true)
    } else {
      setIsBillable10(false)
    }
  }, [isProjectBillable10, isTaskBillable10])

  useEffect(() => {
    if (isProjectBillable11 === true || isTaskBillable11 === true) {
      setIsBillable11(true)
    } else {
      setIsBillable11(false)
    }
  }, [isProjectBillable11, isTaskBillable11])

  useEffect(() => {
    if (isProjectBillable12 === true || isTaskBillable12 === true) {
      setIsBillable12(true)
    } else {
      setIsBillable12(false)
    }
  }, [isProjectBillable12, isTaskBillable12])

  useEffect(() => {
    if (isProjectBillable13 === true || isTaskBillable13 === true) {
      setIsBillable13(true)
    } else {
      setIsBillable13(false)
    }
  }, [isProjectBillable13, isTaskBillable13])

  const getUserTimesheet = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/user-Timesheet/?userID=${userID}`,
        config
      );
      setUserTimesheetInfo(data.timesheets.sort((a, b) => new Date(b.date) - new Date(a.date)));
    } catch (error) {
      console.log("error from get user timesheet api ", error);
    }
  };


  const convertDate = (dateString) => {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    const dateParts = dateString.split(" ");
    const year = dateParts[3];
    const month = months[dateParts[1]];
    const day = dateParts[2];

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  let [box, setBox] = useState(["", "", "", "", ""])
  let newLength = 5;

  const addNewItem = () => {
    if (box.length < 13) {
      setBox(prevBox => [...prevBox, ""]);
    }
  };


  const getWeeklyTimesheetData = async () => {
    setWeeklytimesheetData([])
    const startDate = convertDate(thisWeekDates[0])
    const endDate = convertDate(thisWeekDates[6])


    console.log(startDate, endDate)
    try {
      const { data } = await axios.post("https://app.timetraker.com/backend/api/get-weekly-timesheet", { startDate, endDate, userID }, config)


      // for Hours
      const resultObject = data.timesheets.reduce((acc, item) => {
        acc[item.name] = item.hours;
        return acc;
      }, {});


      // for Project
      let resultObjectForProject = data.timesheets.reduce((acc, item) => {
        acc[item.name] = item.project;
        return acc;
      }, {});




      let newKeyValuesForProject = {
        "1": '',
        "2": '',
        "3": '',
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": "",
        "10": "",
        "11": "",
        "12": "",
        "13": "",
      };

      resultObjectForProject = { ...newKeyValuesForProject, ...resultObjectForProject };





      for (const key in resultObjectForProject) {
        if (resultObjectForProject.hasOwnProperty(key)) {
          const parts = key.split(' ');
          const yearPart = parts[parts.length - 1];
          const year = yearPart.slice(0, 4);
          const lastDigit = yearPart.slice(4);
          const value = resultObjectForProject[key];
          switch (lastDigit) {
            case '1':
              setProject1(value);
              break;
            case '2':
              setProject2(value);
              break;
            case '3':
              setProject3(value);
              break;
            case '4':
              setProject4(value);
              break;
            case '5':
              setProject5(value);
              break;
            case '6':
              setProject6(value);
              break;
            case '7':
              setProject7(value);
              break;
            case '8':
              setProject8(value);
              break;
            case '9':
              setProject9(value);
              break;
            case '10':
              setProject10(value);
              break;
            case '11':
              setProject11(value);
              break;
            case '12':
              setProject12(value);
              break;
            case '13':
              setProject13(value);
              break;
            default:
              break;
          }
        }
      }






      // for Task
      let comp = data.timesheets.reduce((acc, item) => {
        acc[item.name] = item.task;
        return acc;
      }, {});


      console.log("hello comp", comp)
      const lastOne = 5

      for (const key in comp) {
        if (comp.hasOwnProperty(key)) {
          // const lastDigit = key.match(/\d$/)[0]; 
          const parts = key.split(' ');
          const yearPart = parts[parts.length - 1];
          const year = yearPart.slice(0, 4);
          const lastDigit = yearPart.slice(4);

          console.log("hello +lastDigit", +lastDigit)
          console.log("hello +key", key)
          if (lastOne < +lastDigit) {
            console.log("hello")
            newLength = Math.max(0, +lastDigit);
          }
        }
      }

      const newBox = new Array(newLength).fill("");

      console.log("hello", newBox)
      console.log("hello", newLength)

      setBox(newBox);


      let newKeyValues = {
        "1": '',
        "2": '',
        "3": '',
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "9": "",
        "10": "",
        "11": "",
        "12": "",
        "13": "",
      };

      let resultObjectForTask = { ...newKeyValues, ...comp };




      for (const key in resultObjectForTask) {
        if (resultObjectForTask.hasOwnProperty(key)) {
          const parts = key.split(' ');
          const yearPart = parts[parts.length - 1];
          const year = yearPart.slice(0, 4);
          const lastDigit = yearPart.slice(4);

          const value = resultObjectForTask[key];

          switch (lastDigit) {
            case '1':
              setTask1(value);
              break;
            case '2':
              setTask2(value);
              break;
            case '3':
              setTask3(value);
              break;
            case '4':
              setTask4(value);
              break;
            case '5':
              setTask5(value);
              break;
            case '6':
              setTask6(value);
              break;
            case '7':
              setTask7(value);
              break;
            case '8':
              setTask8(value);
              break;
            case '9':
              setTask9(value);
              break;
            case '10':
              setTask10(value);
              break;
            case '11':
              setTask11(value);
              break;
            case '12':
              setTask12(value);
              break;
            case '13':
              setTask13(value);
              break;
            default:
              setTask1("");
              setTask2("");
              setTask3("");
              setTask4("");
              setTask5("");
              break;
          }
        }
      }






      // for Task
      let resultObjectForbillable = data.timesheets.reduce((acc, item) => {
        acc[item.name] = item.billable;
        return acc;
      }, {});




      let newKeyValuesForBillable = {
        "1": '',
        "2": '',
        "3": '',
        "4": "",
        "5": "",
        "6": "",
        "7": "",
        "8": "",
        "9": "",
        "10": "",
        "11": "",
        "12": "",
        "13": "",
      };

      resultObjectForbillable = { ...newKeyValuesForBillable, ...resultObjectForbillable };




      for (const key in resultObjectForbillable) {
        if (resultObjectForbillable.hasOwnProperty(key)) {
          const parts = key.split(' ');
          const yearPart = parts[parts.length - 1];
          const year = yearPart.slice(0, 4);
          const lastDigit = yearPart.slice(4);
          const value = resultObjectForbillable[key];
          switch (lastDigit) {
            case '1':
              setIsBillable1(value);
              break;
            case '2':
              setIsBillable2(value);
              break;
            case '3':
              setIsBillable3(value);
              break;
            case '4':
              setIsBillable4(value);
              break;
            case '5':
              setIsBillable5(value);
              break;
            case '6':
              setIsBillable6(value);
              break;
            case '7':
              setIsBillable7(value);
              break;
            case '8':
              setIsBillable8(value);
              break;
            case '9':
              setIsBillable9(value);
              break;
            case '10':
              setIsBillable10(value);
              break;
            case '11':
              setIsBillable11(value);
              break;
            case '12':
              setIsBillable12(value);
              break;
            case '13':
              setIsBillable13(value);
              break;
            default:
              setIsBillable1("");
              setIsBillable2("");
              setIsBillable3("");
              setIsBillable4("");
              setIsBillable5("");
              break;
          }
        }
      }


      setWeeklytimesheetData(resultObject)

    } catch (error) {
      console.log(error)
    }
  }

  const deleteWeeklyTimesheetData = async () => {
    try {
      const startDate = thisWeekDates[0]
      const endDate = thisWeekDates[6]
      console.log(startDate, endDate)
      const { data } = await axios.post("https://app.timetraker.com/backend/api/delete-weekly-timesheet", { startDate, endDate, userID }, config)
      if (data) {
        setNewTimesheetData(data);
      }

    } catch (error) {
      console.log(error)
    }
  }

  const submitWeeklyTimesheetData = async () => {
    try {
      const startDate = thisWeekDates[0]
      const endDate = thisWeekDates[6]
      console.log(startDate, endDate)
      const { data } = await axios.post("https://app.timetraker.com/backend/api/submit-weekly-timesheet", { startDate, endDate, userID }, config)
      if (data) {
        setNewTimesheetData(data);
      }
    } catch (error) {
      console.log(error)
    }
  }


  const navigate = useNavigate();


  useEffect(() => {
    getAllProject();
    getAllTask();
    getAllClasses()
    getUserPolciy()
    // getUserTimesheet();
    getWeeklyTimesheetData()
    if (!forPhoneScreenNoDisplay) {
      setSideBarStatus(false)
    }
  }, []);

  useEffect(() => {

    getWeeklyTimesheetData()

  }, [thisWeekDates]);




  const handleDataFromChild = (data) => {
    setNewTimesheetData(data);
  };

  const [formDataForUserFilter, setFormDataForUserFilter] = useState({ userID: userID })

  //submitSelectedTimesheets

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const submitSelectedTimesheets = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to submit these Timesheets",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowIds.length < 1) {
            errorNotify("Please select at least 1 Timesheet.")
          } else {
            const { data } = await axios.post(
              `https://app.timetraker.com/backend/api/submit-selected-timesheet/`,
              selectedRowIds,
              config
            );
            if (data) {
              setNewTimesheetData(data);
              successNotify("Your timesheet has been submited")
            }
          }
        } catch (error) {
          console.log("error from submitSelectedTimesheets", error)
        }
      }
    })
  }



  const deleteSelectedTimesheets = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete these Timesheets",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowIds.length < 1) {
            errorNotify("Please select at least 1 Timesheet.")
          } else {
            const { data } = await axios.post(
              `https://app.timetraker.com/backend/api/delete-selected-timesheet/`,
              selectedRowIds,
              config
            );
            if (data) {
              setNewTimesheetData(data);
              successNotify("Your timesheet has been deleted")
            }
          }
        } catch (error) {
          console.log("error from submitAllTimesheets", error)
        }
      }
    })
  }




  const handleChangeForFilterForUser = async (e) => {
    const { name, value } = e.target;
    setFormDataForUserFilter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [formData, setFormData] = useState({
    userID: userID,
    companyID: companyID,
    date: "",
    hours: "",
    project: "",
    task: "",
    name: "",
    billable: true
  });

  console.log("formData", formData)

  // location 

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  console.log("location", location)

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          setError(error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (TimesheetLocationTrack) {
      getLocation();
    }
  }, []);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "classDetails") {
      const data = classesInfo?.filter(item => item._id == value)
      setFormData((prevData) => ({
        ...prevData,
        classDetails: {
          _id: data?.[0]?._id,
          class: data?.[0]?.class,
        }
      }));
    }
  };

  const handleDoubleClick = (name, date) => {

    const convertDate = (dateString) => {
      const months = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12",
      };

      const dateParts = dateString.split(" ");
      const year = dateParts[3];
      const month = months[dateParts[1]];
      const day = dateParts[2];

      const formattedDate = `${year}-${month}-${day}`;
      return formattedDate;
    };

    if (date) {
      var newDate = convertDate(date);
    }
    setFormData((prevData) => ({
      ...prevData,
      name: name,
      date: newDate
    }));
    handleClickOpen()
  }

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(
        `https://app.timetraker.com/backend/api/create-timesheet-entry`,
        {
          date: formData.date,
          name: formData.name,
          project: formData.project,
          classDetails: formData.classDetails,
          task: formData.task,
          hours: formData.hours,
          userID: userID,
          companyID: companyID,
          userName: userName,
          billable: formData.billable,
          description: formData.description,
          location: location
        },
        config
      );
      if (data.locationNull) {
        errorNotify("Please enable location access.")
        return
      }
      if (data.AnotherLocation) {
        errorNotify("You are not in the expected location!")
        return
      }
      if (data) {
        window.location.reload()
      }

    } catch (error) {
      console.log(error);
    }
  }



  const successNotify = (message) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const errorNotify = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };



  useEffect(() => {
    const filterTimesheetForUser = async () => {

      try {
        const { data } = await axios.post(
          `https://app.timetraker.com/backend/api/filter-user-timesheet`, { formDataForUserFilter },
          config
        );
        if (data) {
          setUserTimesheetInfo(data.filteredTimesheet.sort((a, b) => new Date(b.date) - new Date(a.date)));
        }
      } catch (error) {
        console.log("error from filteruserTimesheet", error)
      }
    }
    filterTimesheetForUser()
  }, [formDataForUserFilter])

  useEffect(() => {
    getUserTimesheet();
    getWeeklyTimesheetData()
  }, [newTimesheetData]);

  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const getWeekByCalendar = () => {
      const today = startDate;
      const dayOfWeek = today.getDay();
      const diff = today.getDate() - dayOfWeek + 1;

      const lastSunday = new Date(today);
      lastSunday.setDate(diff);

      const dates = [];

      for (let i = 0; i < 7; i++) {
        const tempDate = new Date(lastSunday);
        tempDate.setDate(lastSunday.getDate() + i);
        dates.push(tempDate.toDateString()); // You can format this date as needed
      }


      return dates;
    }
    setThisWeekDates(getWeekByCalendar())
  }, [startDate])


  useEffect(() => {
    getAllProject();
    getAllClasses()
    getAllTask();
    getUserPolciy()
    // getUserTimesheet();
    getWeeklyTimesheetData();

    // if (!forPhoneScreenNoDisplay) {
    //   setSideBarStatus(false)
    // }
  }, [reload]);



  return (
    <AdminLayout>


      < ToastContainer />


      <div
        className={sideBarStatus ? "content_right_dashboard" : "none"}
        fluid
        style={
          (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
            ? { display: "none" }
            : { display: "block" }
        }
      >

        {/* Row 1 */}
        <div
          className="hamburgar"
          style={sideBarStatus ? { display: "none" } : { display: "block" }}
        >
          <i onClick={handleClick} className="fas fa-bars"></i>
        </div>

        <Row>
          <Col md={12} className="dash-container2">
            <div className="header-top2">
              <div className="dash-header1">
                <h5
                  className=""
                  style={
                    sideBarStatus == true
                      ? { paddingLeft: "10px", marginTop: "5px" }
                      : { paddingLeft: "60px", marginTop: "5px" }
                  }
                >
                  My Time
                </h5>
              </div>


              <div className="profile-header2">
                <i style={{ display: 'none' }} class="fa-solid fa-hourglass-end"></i>{" "}
                <span className="hours-tieims" style={{ display: 'none' }} > 06 Hr : 15 Min : 30 Sec</span>
                <ProfileComponent />
              </div>
            </div>
          </Col>
        </Row>

        <Row style={{ background: "#F1F1F1" }} className="mt-0">
          <Col>
            <div
              className="time"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "20px"
              }}
            >


              <div className="text-time">
                <p
                  style={{ cursor: "pointer" }}
                  className="para1"
                  onClick={() => {
                    navigate("/my-time");
                  }}
                >
                  Day View
                </p>

                <p
                  style={{ backgroundColor: "#64E48E", cursor: "pointer" }}
                  className="para2"
                  onClick={() => {
                    navigate("/weekly-timesheet");
                  }}
                >
                  Week View
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  className="para3"
                  onClick={() => {
                    navigate("/calendar-timesheet")
                  }}
                >
                  Calendar
                </p>
              </div>
            </div>
          </Col>
        </Row>



        <Row style={{ background: "#F1F1F1" }}>
          <Col className="weekly_buttons_parent" md={12}>
            <div className="button-container button-container-weekly-top">
              <button title="Previous week" className='button-container-button previous_next' onClick={prevWeekTimesheet} style={{ cursor: "pointer" }}><i class="fa-solid fa-circle-left" style={{ color: "#04542c", fontSize: "20px" }}></i></button>
              <button className='button-container-button' onClick={currentWeekTimesheet} style={{ cursor: "pointer" }}>Current Week</button>
              <DatePicker style={{ cursor: "pointer" }} value="Choose Date" selected={startDate} onChange={(date) => setStartDate(date)} />
              <button title="Next week" className='button-container-button previous_next' onClick={nextWeekTimesheet} style={{ cursor: "pointer" }}><i class="fa-solid fa-circle-right" style={{ color: "#04542c", fontSize: "20px" }}></i></button>
            </div>
            <div style={{ justifyContent: 'center', marginRight: "30px" }} className="button-container">
              <button className='button-container-button' onClick={submitWeeklyTimesheetData} style={{ cursor: 'pointer' }} >Submit week</button>
              <button className='button-container-button' onClick={deleteWeeklyTimesheetData} style={{ cursor: 'pointer' }} >Delete week</button>
            </div>
          </Col>
        </Row>
        <form id="resetForm">
          <div className="form_div" style={{ width: '100%', display: 'flex', justifyContent: "center", alignItems: 'center' }}>
            <div className="top-view">
              {/* <button onClick={navigateToDaily}>Daily view</button> */}
              <h1 style={{ textAlign: 'center' }} >Weekly Timesheet</h1>

              <div className="scroll_weelky_table" >
                <div style={{ display: "flex", width: '1087px', backgroundColor: "#80e6a2", }}>

                  <div className="project-heading">
                    <p>Project</p>
                    <p>Task</p>
                    <p>Billable</p>
                  </div>

                  <ul
                    style={{
                      padding: '0px',
                      display: "flex",
                      listStyle: "none",
                      // Width: "40%",
                      height: "70px",
                      backgroundColor: "#80e6a2",
                      color: "#fff",
                      paddingTop: "10px"
                    }}
                  >
                    {thisWeekDates.map((date, index) => {
                      const updatedweek = date.slice(0, -11);
                      const updatedDay = date.slice(4, -4);
                      return (
                        <li
                          style={{ margin: "0px 17px", fontSize: "12px", minWidth: '90px' }}
                          key={index}
                        >
                          {updatedweek}<br />
                          {updatedDay}
                        </li>
                      )
                    })}
                  </ul>
                </div>

                {box?.map((item, i) => {
                  let count = i + 1
                  let viewProject = project1
                  let viewTask = task1
                  let viewisBillable = isBillable1
                  let viewHandleChangeProject = handleChangeProject1
                  let viewHandleChangeTask = handleChangeTask1
                  let viewHandleChangeBillable = handleChangeBillable1
                  switch (count) {
                    case 1:
                      viewProject = project1
                      viewTask = task1
                      viewisBillable = isBillable1
                      viewHandleChangeProject = handleChangeProject1
                      viewHandleChangeTask = handleChangeTask1
                      viewHandleChangeBillable = handleChangeBillable1
                      break;
                    case 2:
                      viewProject = project2
                      viewTask = task2
                      viewisBillable = isBillable2
                      viewHandleChangeProject = handleChangeProject2
                      viewHandleChangeTask = handleChangeTask2
                      viewHandleChangeBillable = handleChangeBillable2
                      break;
                    case 3:
                      viewProject = project3
                      viewTask = task3
                      viewisBillable = isBillable3
                      viewHandleChangeProject = handleChangeProject3
                      viewHandleChangeTask = handleChangeTask3
                      viewHandleChangeBillable = handleChangeBillable3
                      break;
                    case 4:
                      viewProject = project4
                      viewTask = task4
                      viewisBillable = isBillable4
                      viewHandleChangeProject = handleChangeProject4
                      viewHandleChangeTask = handleChangeTask4
                      viewHandleChangeBillable = handleChangeBillable4
                      break;
                    case 5:
                      viewProject = project5
                      viewTask = task5
                      viewisBillable = isBillable5
                      viewHandleChangeProject = handleChangeProject5
                      viewHandleChangeTask = handleChangeTask5
                      viewHandleChangeBillable = handleChangeBillable5
                      break;
                    case 6:
                      viewProject = project6
                      viewTask = task6
                      viewisBillable = isBillable6
                      viewHandleChangeProject = handleChangeProject6
                      viewHandleChangeTask = handleChangeTask6
                      viewHandleChangeBillable = handleChangeBillable6
                      break;
                    case 7:
                      viewProject = project7
                      viewTask = task7
                      viewisBillable = isBillable7
                      viewHandleChangeProject = handleChangeProject7
                      viewHandleChangeTask = handleChangeTask7
                      viewHandleChangeBillable = handleChangeBillable7
                      break;
                    case 8:
                      viewProject = project8
                      viewTask = task8
                      viewisBillable = isBillable8
                      viewHandleChangeProject = handleChangeProject8
                      viewHandleChangeTask = handleChangeTask8
                      viewHandleChangeBillable = handleChangeBillable8
                      break;
                    case 9:
                      viewProject = project9
                      viewTask = task9
                      viewisBillable = isBillable9
                      viewHandleChangeProject = handleChangeProject9
                      viewHandleChangeTask = handleChangeTask9
                      viewHandleChangeBillable = handleChangeBillable9
                      break;
                    case 10:
                      viewProject = project10
                      viewTask = task10
                      viewisBillable = isBillable10
                      viewHandleChangeProject = handleChangeProject10
                      viewHandleChangeTask = handleChangeTask10
                      viewHandleChangeBillable = handleChangeBillable10
                      break;
                    case 11:
                      viewProject = project11
                      viewTask = task11
                      viewisBillable = isBillable11
                      viewHandleChangeProject = handleChangeProject11
                      viewHandleChangeTask = handleChangeTask11
                      viewHandleChangeBillable = handleChangeBillable11
                      break;
                    case 12:
                      viewProject = project12
                      viewTask = task12
                      viewisBillable = isBillable12
                      viewHandleChangeProject = handleChangeProject12
                      viewHandleChangeTask = handleChangeTask12
                      viewHandleChangeBillable = handleChangeBillable12
                      break;
                    case 13:
                      viewProject = project13
                      viewTask = task13
                      viewisBillable = isBillable13
                      viewHandleChangeProject = handleChangeProject13
                      viewHandleChangeTask = handleChangeTask13
                      viewHandleChangeBillable = handleChangeBillable13
                      break;
                    default:
                      break;
                  }

                  const handleAdd = (itemcount) => {
                    if (box.length == itemcount) {
                      addNewItem()
                    }
                  }
                  return (
                    <ul style={{ display: "flex", listStyle: "none", padding: '0', }}>
                      <Form.Select
                        style={{ Width: "200px", cursor: 'pointer' }}
                        aria-label="Default select example"
                        value={viewProject}
                        required
                        onChange={viewHandleChangeProject}
                      >
                        <option value=''  >
                          Select
                        </option>
                        {projectInfo &&
                          projectInfo.map((item, index) => {
                            return (
                              <option key={index} value={item.project}>
                                {item.project}
                              </option>
                            );
                          })}
                      </Form.Select>

                      {/* <label htmlFor="">Task</label> */}
                      <Form.Select style={{ cursor: 'pointer' }}
                        aria-label="Default select example"
                        value={viewTask}
                        required
                        onChange={viewHandleChangeTask}
                      >
                        <option value=''>
                          Select
                        </option>
                        {/* {taskInfo &&
                          taskInfo.map((item, index) => {
                            return (
                              <option key={index} value={item.task}>
                                {item.task}
                              </option>
                            );
                          })} */}
                        {viewProject === "" || !viewProject ? policy &&
                          policy.map((item, index) => {
                            return (
                              <option key={index} value={item?.policyDetails?.policyName}>
                                {item?.policyDetails?.policyName}
                              </option>
                            );
                          }) :
                          taskInfo.map((item, index) => {
                            return (
                              <option key={index} value={item.task}>
                                {item.task}
                              </option>
                            );
                          })}
                      </Form.Select>

                      <div
                        className="weekly_billable"
                      >
                        <input
                          onChange={viewHandleChangeBillable}
                          checked={viewisBillable}
                          type="checkbox"
                        />
                      </div>

                      {thisWeekDates.map((date, index) => {
                        const name = `${date}${count}`;

                        return (
                          <div>
                            <input

                              name={name}
                              defaultValue={weeklytimesheetData[name]}
                              className="input_time_weekly"
                              style={name.charAt(0) === "S" ? {
                                height: "40px",
                                margin: "0px 0px",
                                borderRadius: "none",
                                outline: "none",
                                background: "rgb(233 233 233)"
                              } : {
                                height: "40px",
                                margin: "0px 0px",
                                borderRadius: "none",
                                outline: "none",
                              }}
                              data-id={date}
                              data-project={viewProject}
                              data-task={viewTask}
                              data-billable={viewisBillable}
                              onClick={() => handleAdd(count)}
                              onChange={handleChange}
                              onDoubleClick={() => { handleDoubleClick(name, date) }}
                            />
                          </div>
                        );
                      })}
                    </ul>
                  )
                })}


                {/* 2 */}







                {/* {
                  policy && policy?.map((item, index) => {
                    const count = 5 + index + 1
                    return (
                      <ul key={index} style={{ display: "flex", listStyle: "none", padding: '0', }}>
                        <Form.Select
                          style={{ cursor: 'pointer' }}
                          aria-label="Default select example"
                        // value={project5}
                        // required
                        // onChange={handleChangeProject5}
                        >
                          <option value=''>
                            Time Off
                          </option>

                        </Form.Select>

                        <Form.Select
                          style={{ cursor: 'pointer' }}
                          aria-label="Default select example"
                          required
                      
                        >
                          <option value={item?.policyDetails?.policyName}>
                            {item?.policyDetails?.policyName}
                          </option>

                        </Form.Select>
                        <div
                          className="weekly_billable"
                        >
                          <input
                            checked={false}
                            type="checkbox"
                          />
                        </div>
                        {thisWeekDates.map((date, index) => {

                          const name = `${date}${count}`;
                          return (
                            <div>
                              <input
                                name={name}
                                defaultValue={weeklytimesheetData[name]}
                                className="input_time_weekly"
                                style={name.charAt(0) === "S" ? {
                                  height: "40px",
                                  margin: "0px 0px",
                                  borderRadius: "none",
                                  outline: "none",
                                  background: "rgb(233 233 233)"
                                } : {
                                  height: "40px",
                                  margin: "0px 0px",
                                  borderRadius: "none",
                                  outline: "none",
                                }}
                                data-id={date}
                                data-project=""
                                data-task={item?.policyDetails?.policyName}
                                data-billable={false}
                                onChange={handleChange}
                              />
                            </div>
                          );
                        })}
                      </ul>
                    )
                  })
                } */}






              </div>
              <div className="weekly_table_end">

              </div>
            </div>
          </div>
        </form>







        <Row style={{ background: "#F1F1F1" }}>
          <Col md={12}>
            <div className="time weekly_filters">
              <div className="text-time">
                {/* <input type="text" name="" id="" placeholder="Search" /> */}

                <Form className="input-menu" >
                  <Form.Select
                    className="input-menu-focus"
                    style={{ cursor: 'pointer' }}
                    name="project"
                    aria-label="Default select example"
                    onChange={handleChangeForFilterForUser}

                  >
                    <option value="">Project</option>
                    {projectInfo &&
                      projectInfo.map((item, index) => {
                        return (
                          <option key={index} value={item.project}>
                            {item.project}
                          </option>
                        );
                      })}
                  </Form.Select>

                  <Form.Select
                    className="input-menu-focus"
                    style={{ cursor: 'pointer' }}
                    name="task"
                    aria-label="Default select example"
                    onChange={handleChangeForFilterForUser}


                  >
                    <option value="">Task</option>
                    {taskInfo &&
                      taskInfo.map((item, index) => {
                        return (
                          <option key={index} value={item.task}>
                            {item.task}
                          </option>
                        );
                      })}
                  </Form.Select>

                  <Form.Select
                    className="input-menu-focus"
                    style={{ cursor: 'pointer' }}
                    name="state"
                    aria-label="Default select example"
                    onChange={handleChangeForFilterForUser}
                  >
                    <option value="">Status</option>
                    <option value="hold">Hold</option>
                    <option value="submited">Submitted</option>
                    <option value="aproved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </Form.Select>


                </Form>
              </div>

              <div className="button-container">
                <button className='button-container-button' onClick={submitSelectedTimesheets} style={{ cursor: "pointer" }}>Submit</button>
                <button className='button-container-button' onClick={handleClickOpen2} style={{ cursor: "pointer" }}>Print</button>
                <button className='button-container-button' onClick={deleteSelectedTimesheets} style={{ cursor: "pointer" }}>Delete</button>
              </div>
            </div>
          </Col>
        </Row>


        <Row style={{ background: "#F1F1F1" }}>
          <Col>
            <div className="user-timesheet-table-parent">
              <UserTimeSheetTable
                userTimesheetInfo={userTimesheetInfo}
                setUserTimesheetInfo={setUserTimesheetInfo}
                handleDataFromChild={handleDataFromChild}
                selectedRowIds={selectedRowIds}
                setSelectedRowIds={setSelectedRowIds}
              />
            </div>
          </Col>
        </Row>



        <Dialog
          open={open2}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose2}
          aria-describedby="alert-dialog-slide-description"
        >
          <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
            <div style={{ marginBottom: "40px" }} className="button-container">
              <UserPdf className='button-container-button' userTimesheetInfo={userTimesheetInfo} />
              <UserExcel className='button-container-button' userTimesheetInfo={userTimesheetInfo} />
              <UserCsv className='button-container-button' userTimesheetInfo={userTimesheetInfo} />
            </div>
            <div className="button-container">
              <button className='button-container-button' onClick={handleClose2} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
            </div>
          </div>
        </Dialog>






        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <form
            onSubmit={handleSubmit}
            className="dialog-main"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <DialogActions>
                <Button
                  style={{

                    background: "rgb(100, 228, 142)",
                    borderRadius: "10px",
                    color: "#000",
                    fontWeight: "600",
                    padding: "5px 15px",
                  }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            </div>

            <div className="border-around">
              <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">
                <div style={{ width: "50%", display: 'flex', flexDirection: 'column', gap: '5px' }} className="customer">
                  <label className="lable_bold" htmlFor="">
                    Hours
                    <i className="fa-sharp fa-light fa-asterisk"></i>
                  </label>
                  <input
                    style={{ height: '40px' }}
                    name="hours"
                    required
                    onChange={handleInputChange}
                    type="number"
                  />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: "50%" }} className="task">
                  <label className="lable_bold" htmlFor="">

                    Date
                    <i className="fa-sharp fa-light fa-asterisk"></i>
                  </label>
                  <input
                    style={{ fontFamily: 'Inter', fontSize: '15px', height: '40px' }}
                    name="date"
                    type="date"
                    value={formData?.date}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">
                <div style={{ width: "50%" }} className="customer">
                  <label className="lable_bold" htmlFor="">
                    Customer:Project
                    <i className="fa-sharp fa-light fa-asterisk"></i>
                  </label>
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    className="area"
                    name="project"
                    aria-label="Default select example"
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {projectInfo &&
                      projectInfo.map((item, index) => {
                        return (
                          <option key={index} value={item.project}>
                            {item.project}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>

                <div style={{ width: "50%" }} className="task">
                  <label className="lable_bold" htmlFor="">
                    Task
                    <i className="fa-sharp fa-light fa-asterisk"></i>
                  </label>
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    className="area"
                    name="task"
                    aria-label="Default select example"
                    required
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>
                    {taskInfo &&
                      taskInfo.map((item, index) => {
                        return (
                          <option value={item.task}>
                            {item.task}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>
              </div>







              <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "flex" }} className="form-time1 ">


                <div style={additionalSettings.timesheetClasses === true ? { width: "50%" } : { display: "none" }} className="task">
                  <label className="lable_bold" htmlFor="">
                    Class
                    <i className="fa-sharp fa-light fa-asterisk"></i>
                  </label>
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    className="area"
                    name="classDetails"
                    aria-label="Default select example"
                    required={additionalSettings?.timesheetClasses ? additionalSettings?.timesheetClasses : false}
                    onChange={handleInputChange}
                  >
                    <option value="">Select</option>

                    {classesInfo &&
                      classesInfo.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>
                            {item.class}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>

                <div style={additionalSettings.timesheetClasses === true ? { display: "none" } : { width: "50%", marginTop: "3px" }} className="customer">
                  <label className="lable_bold" htmlFor="">
                    Description
                  </label>
                  <textarea className="edit_textarea" onChange={handleInputChange} type="text" name="description" id="">
                  </textarea>
                </div>

                <div style={{ width: "50%" }} className="task">
                  <label className="lable_bold" htmlFor="">
                    Billable
                    <i className="fa-sharp fa-light fa-asterisk"></i>
                  </label>
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    className="area"
                    name="billable"
                    aria-label="Default select example"
                    required
                    onChange={handleInputChange}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                </div>
              </div>

              <div style={additionalSettings.timesheetClasses === true ? { display: "flex", padding: "0px" } : { display: "none" }} className="form-time1 ">
                <div style={{ width: "500%", marginTop: "3px" }} className="customer">
                  <label className="lable_bold" htmlFor="">
                    Description
                  </label>
                  <textarea className="edit_textarea" onChange={handleInputChange} type="text" name="description" id="">
                  </textarea>
                </div>
              </div>


              <div className="strong">
                <button type="submit" style={{ border: "none", background: "white" }}>
                  <strong style={{ display: "flex" }}>
                    Save
                  </strong>
                </button>
              </div>
            </div>
          </form>
        </Dialog>


      </div>
    </AdminLayout>
  );
};

export default AdminWeeklyTimesheet;