import React, { useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import signupImg2 from "../img/user2.png";
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContentText,
    DialogContent,
} from "@mui/material";

const ProfileComponent = () => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const adminLoginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

    if (adminLoginInfo) {
        var profileInfo = adminLoginInfo;
        var token = adminLoginInfo?.token
        var companyID = adminLoginInfo?._id;
    } else {
        var profileInfo = userLoginInfo;
        var token = userLoginInfo?.token
        var companyID = userLoginInfo?.companyID;
    }

    //     // config

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        handleCloseUserMenu()
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };


    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };


    const navigate = useNavigate()
    const handleLogout = () => {
        if (profileInfo.role == "admin") {
            localStorage.removeItem("adminLoginInfo");
            navigate("/login")
        } else {
            localStorage.removeItem("userLoginInfo");
            navigate("/login")
        }
    };

    const deleteDemoData = async (req, res) => {
        try {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/remove-demo-data/?companyID=${companyID}`, config)
            if (data) {
                successNotify('Demo data has been removed.')
                handleCloseUserMenu()
            }
        } catch (error) {
            console.log("error", error)
            errorNotify("Something went wrong.")
        }
    }




    const [formData, setFromData] = useState({ userID: profileInfo._id });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    console.log(formData);

    const validatePassword = (n_password) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(n_password);
    };


    const handleSubmit = async (e) => {
        if (validatePassword(formData.n_password)) {
            e.preventDefault();
            setOpen(false);
            if (profileInfo.role == "admin") {
                try {
                    const { data } = await axios.post(
                        "https://app.timetraker.com/backend/api/admin-update-pass",
                        formData,
                        config
                    );
                    if (data) {
                        token = data.token
                        adminLoginInfo.token = data.token;
                        localStorage.setItem("adminLoginInfo", JSON.stringify(adminLoginInfo));

                        successNotify("your password is updated")
                    }
                } catch (error) {
                    console.log(error);
                    errorNotify("invalid password");
                }
            } else if (profileInfo.role == "sub-admin") {
                try {
                    const { data } = await axios.post(
                        "https://app.timetraker.com/backend/api/sub-admin-update-pass",
                        formData,
                        config
                    );
                    if (data) {
                        token = data.token
                        adminLoginInfo.token = data.token;
                        localStorage.setItem("adminLoginInfo", JSON.stringify(adminLoginInfo));

                        successNotify("your password is updated");
                    }
                } catch (error) {
                    console.log(error);
                    errorNotify("invalid password");
                }
            } else if (profileInfo.role == "manager") {
                try {
                    const { data } = await axios.post(
                        "https://app.timetraker.com/backend/api/manager-update-pass",
                        formData,
                        config
                    );
                    if (data) {
                        successNotify("your password is updated");
                    }
                } catch (error) {
                    console.log(error);
                    errorNotify("invalid password");
                }
            } else if (profileInfo.role == "user") {
                try {
                    const { data } = await axios.post(
                        "https://app.timetraker.com/backend/api/user-update-pass",
                        formData,
                        config
                    );
                    if (data) {
                        successNotify("your password is updated");
                    }
                } catch (error) {
                    console.log(error);
                    errorNotify("invalid password");
                }
            }
        } else {
            e.preventDefault();
            alert("Password must be contain 8 characters with atleast one uppercase letter, one lowercase letter, and one number");
        }

    };



    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };
    return (
        <abbr title="Profile">
            <ToastContainer />
            <img
                onClick={handleOpenUserMenu}
                src={signupImg2}
                alt=""
            />
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "start", background: "white", padding: "10px 15px" }} >
                    <Typography className='profile_typo' textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-user"></i> {profileInfo?.name} ({profileInfo?.role}) </Typography>
                    {adminLoginInfo?.token ?
                        <Typography className='profile_typo' onClick={() => { navigate("/set-up-account") }} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-gear"></i> Setup Wizard </Typography>
                        : null}
                    {adminLoginInfo?.token ?
                        <Typography className='profile_typo' onClick={deleteDemoData} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-trash"></i> Remove Demo Data</Typography>
                        : null}
                    {adminLoginInfo?.token ?
                        <Typography className='profile_typo' onClick={handleClickOpen} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-lock"></i>Change Password</Typography>
                        : null}
                    <Typography className='profile_typo' onClick={handleLogout} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-right-from-bracket"></i> Log Out</Typography>
                </div>
            </Menu>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle sx={{ padding: "20px" }} id="alert-dialog-title">
                    {
                        "Please fill the below form to changer your password"
                    }
                </DialogTitle>
                <DialogContent sx={{ padding: "20px" }}>
                    <label>Current Password</label>
                    <input
                        type="password"
                        onChange={handleChange}
                        name="c_password"
                    />
                    <label style={{ marginTop: "20px" }}>New Password</label>
                    <input
                        type="password"
                        onChange={handleChange}
                        name="n_password"
                    />
                </DialogContent>
                <DialogActions sx={{ padding: "20px", paddingTop: "0px" }}>
                    <Button sx={{ color: "#05542d" }} onClick={handleClose}>Close</Button>
                    <Button
                        sx={{
                            color: "#f9f9f9",
                            background: "#05542d",
                            border: "1px solid transparent",
                            "&:hover": {
                                background: "#fff",
                                color: "#05542d",
                                border: "1px solid #05542d",
                            },
                        }}
                        onClick={handleSubmit}
                        autoFocus
                    >
                        Save
                    </Button>

                </DialogActions>
            </Dialog>
        </abbr>
    )
}

export default ProfileComponent
