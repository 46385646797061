import React, { useEffect, useState, useContext } from "react";
import "./TimesheetAprove.css";
import AdminLayout from "../../hoc/AdminLayout";
import axios from "axios";
import { Col, Container, Row, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import signupImg1 from "../../img/Vector.png";
import signupImg2 from "../../img/user2.png";
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { MyContext } from "../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import AproveTimesheetTable from "../../components/AproveTimesheetTable.js";
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import Swal from "sweetalert2";
import CryptoJS from 'crypto-js';
import Loder from "../../components/Loder";
import LoderForExpenseDownload from "../../components/LoderForExpenseDownload.js";
import Slide from "@mui/material/Slide";
import ExpenseUserPdf from "../../components/ExpenseExport/ExpenseUserPdf.js";
import ExpenseUserExcel from "../../components/ExpenseExport/ExpenseUserExcel.js";
import ExpenseUserCsv from "../../components/ExpenseExport/ExpenseUserCsv.js";

import MapComponent from "../../components/map/MapComponent";
import LocationOnIcon from '@mui/icons-material/LocationOn';



const columnsForAllSubmitedExpenseTable = [
  { id: "date", label: "Date", minWidth: 150 },
  { id: "name", label: "Name", minWidth: 120 },
  { id: "amount", label: "Amount", minWidth: 100 },
  { id: "Paid By", label: "Paid By", minWidth: 150 },
  { id: "Description", label: "Description", minWidth: 150 },
  { id: "expense", label: "Expense", minWidth: 150 },
  { id: "project", label: "Project", minWidth: 150 },
  { id: "image", label: "Image", minWidth: 100 },
  { id: "check", label: "Check", minWidth: 100, align: "center" },
  { id: "action", label: "Action", minWidth: 120, align: "center" },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
];

const columnsForAllSubmitedExpenseTableWithLocation = [
  { id: "date", label: "Date", minWidth: 150 },
  { id: "name", label: "Name", minWidth: 120 },
  { id: "amount", label: "Amount", minWidth: 100 },
  { id: "Paid By", label: "Paid By", minWidth: 150 },
  { id: "Description", label: "Description", minWidth: 170 },
  { id: "expense", label: "Expense", minWidth: 120 },
  { id: "project", label: "Project", minWidth: 120 },
  { id: "image", label: "Image", minWidth: 100 },
  { id: "check", label: "Check", minWidth: 100, align: "center" },
  { id: "action", label: "Action", minWidth: 120, align: "center" },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
  { id: "address", label: "Address", minWidth: 250, align: "center" }
];

// temporory data

const data = [
  {
    task: "Development",
    status: "Active",
    createdAt: "2023-06-07T04:12:17.986Z",
  },
  {
    task: "Development",
    status: "Active",
    createdAt: "2023-06-07T04:12:17.986Z",
  },
  {
    task: "Development",
    status: "Active",
    createdAt: "2023-06-07T04:12:17.986Z",
  },
];


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpenseAprove = () => {
  const { sideBarStatus, setSideBarStatus } = useContext(MyContext);
  const [newData, setNewData] = useState();

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false);
    } else {
      setSideBarStatus(true);
    }
  };

  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true;
  } else {
    var forPhoneScreenNoDisplay = false;
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const navigate = useNavigate();

  const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
  const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

  if (adminloginInfo) {
    var companyID = adminloginInfo?._id;
    var userID = adminloginInfo?._id;
    var token = adminloginInfo?.token
    var ExpenseLocationTrack = adminloginInfo.accessibility.ExpenseLocationTrack;
  } else if (userLoginInfo) {
    var companyID = userLoginInfo?.companyID;
    var userID = userLoginInfo?._id;
    var token = userLoginInfo?.token
    var ExpenseLocationTrack = userLoginInfo.accessibility.ExpenseLocationTrack;
  }


  //     // config

  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  const [openreceipt, setOpenReceipt] = React.useState(false);

  const handleOpenRecept = () => {
    setOpenReceipt(true);
  };

  const handleCloseRecept = () => {
    setOpenReceipt(false);
  };



  const [allsubmitedExpense, setAllsubmitedExpense] = useState([])
  const [allsubmitedExpensesForFilters, setAllsubmitedExpensesForFilters] = useState([])
  const [allExpenses, setAllExpenses] = useState([])
  const [projectInfo, setProjectInfo] = useState([]);
  const [chartOfAccount, setChartOfAccount] = useState([]);
  const [allTheEmployee, setAllTheEmployee] = useState([])
  const [filters, setFilters] = useState({
    project: '',
    task: '',
    employee: '',
  });

  const [activeTab, setActiveTab] = useState("pendingApprovalTab")

  const [dateForFilter, setDateForFilter] = useState({ companyID: companyID })
  const handlechangeFilterDate = (e) => {
    const { name, value } = e.target;
    setDateForFilter((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const [reversSort, setReversSort] = useState(false)

  const handleChangeSort = () => {
    setReversSort(prevToggle => !prevToggle);
  };


  useEffect(() => {
    if (reversSort) {
      const data = [...allsubmitedExpense].sort((a, b) => new Date(a.date) - new Date(b.date));
      setAllsubmitedExpense(data)
    } else {
      const data = [...allsubmitedExpense].sort((a, b) => new Date(b.date) - new Date(a.date));
      setAllsubmitedExpense(data)
    }
  }, [reversSort])


  const [selectedRowItems, setSelectedRowItems] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])

  const handleCheck = (item) => {
    setSelectedRowItems(prevselectedRowItems => {
      if (prevselectedRowItems.includes(item)) {
        return prevselectedRowItems.filter(item => item !== item);
      } else {
        return [...prevselectedRowItems, item];
      }
    });

    setSelectedRowIds(prevselectedRowIds => {
      if (prevselectedRowIds.includes(item._id)) {
        return prevselectedRowIds.filter(item => item !== item._id);
      } else {
        return [...prevselectedRowIds, item._id];
      }
    })
  };



  const [allCheck, setAllCheck] = useState(false)

  const handleChnageAllCheck = () => {
    if (allCheck === false) {
      setAllCheck(true)
      if (rowsPerPage > 0) {
        allsubmitedExpense?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
          setSelectedRowItems(prevselectedRowItems => {
            return [...prevselectedRowItems, item];
          })

        })
      }
    } else {
      setAllCheck(false)
      setSelectedRowItems([])
    }
  }


  // get all project

  const getAllProject = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-project/?companyID=${companyID}`,
        config
      );
      setProjectInfo(data.projects);
    } catch (error) {
      console.log('error', error)
    }
  };

  // get all task

  const getAllChartOfAccount = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/get-chart-of-account-for-expense/?companyID=${companyID}`,
        config
      );
      setChartOfAccount(data.chartOfAccount);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeForFilterForUser = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    let filteredData = allsubmitedExpensesForFilters;

    if (filters.project) {
      filteredData = filteredData.filter(expense => expense.project === filters.project);
    }

    if (filters.expense) {
      filteredData = filteredData.filter(expense => expense.expense === filters.expense);
    }

    if (filters.employee) {
      filteredData = filteredData.filter(expense => expense.userName === filters.employee);
    }

    setAllsubmitedExpense(filteredData);
  }, [filters, allsubmitedExpensesForFilters]);

  const handleSubmitFilterData = async (e) => {
    e.preventDefault();
    handleClose()
    let filteredData = allsubmitedExpensesForFilters;


    if (dateForFilter.startDate && dateForFilter.endDate) {

      const finalData = filteredData.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= new Date(dateForFilter.startDate) && itemDate <= new Date(dateForFilter.endDate);
      });

      setAllsubmitedExpense(finalData)

    }
  }

  const pendingSubmissionTab = () => {
    setActiveTab("pendingSubmissionTab")
    const pendingSubmissionData = allExpenses.filter((item) => item.state == 'hold' || item.state == 'rejected')
    setAllsubmitedExpense(pendingSubmissionData)
  }

  const pendingApprovalTab = () => {
    setActiveTab("pendingApprovalTab")
    const pendingApprovalData = allExpenses.filter((item) => item.state == 'submited')
    setAllsubmitedExpense(pendingApprovalData)
  }

  const pendingDownloadTab = () => {
    setActiveTab("pendingDownloadTab")
    const pendingDownloadData = allExpenses.filter((item) => item.state == 'aproved' && item.sendToQb == false)
    setAllsubmitedExpense(pendingDownloadData)
  }

  const downloadedTab = () => {
    setActiveTab("downloadedTab")
    const downlodedData = allExpenses.filter((item) => item.state == 'aproved' && item.sendToQb == true)
    setAllsubmitedExpense(downlodedData)
  }

  const aproveExpense = async (itemID, ExpenseOwnerID) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to approve this Expense",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (adminloginInfo?.token) {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/aprove-expense/?expenseID=${itemID}`, config)
            if (data) {
              TimesheetAprovedNotfy()
              // handleClose1()
              setNewData(data)
            }
          } else {
            const { data } = await axios.post(`https://app.timetraker.com/backend/api/aprove-expense-by-approver`, { expenseID: itemID, userID: ExpenseOwnerID, approverID: userID }, config)
            if (data) {
              TimesheetAprovedNotfy()
              // handleClose1()
              setNewData(data)
            }
          }
        }
        catch (error) {
          console.log("approveExpense error", error)
        }
      }
    })
  }


  const [rejectID, setRejectID] = useState("")
  const [userRejectID, setUserRejectID] = useState("")
  const [rejectReason, setRejectReason] = useState("")


  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event, _id, expenseOwner) => {
    setRejectID(_id)
    setUserRejectID(expenseOwner)
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const handleChangeRejectReason = (e) => {
    setRejectReason(e.target.value)
  }


  const rejectExpence = async () => {
    handleCloseUserMenu()

    Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this Expense",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (adminloginInfo?.token) {
            const { data } = await axios.post(`https://app.timetraker.com/backend/api/reject-expense`,
              { rejectID: rejectID, rejectReason: rejectReason },
              config)
            if (data) {
              TimesheetRejectNotfy()
              setNewData(data)
              getAllSubmitedExpense()
            }
          } else {
            const { data } = await axios.post(`https://app.timetraker.com/backend/api/reject-expense-by-approver`,
              { rejectID: rejectID, rejectReason: rejectReason, userID: userRejectID, approverID: userID },
              config)
            if (data) {
              TimesheetRejectNotfy()
              setNewData(data)
              getAllSubmitedExpense()
            }
          }
        }
        catch (error) {
          console.log("approveTimesheet error", error)
        }
      }
    })
  }

  const [expenseId, setExpenseId] = React.useState('')
  const getImage = async (itemID) => {
    setExpenseId(itemID)
    handleOpenRecept()
  }

  const getAllSubmitedExpense = async () => {

    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-expense/?companyID=${companyID}`,
        config
      );

      const allExpenses = data.expenses.sort((a, b) => new Date(b.date) - new Date(a.date))

      setAllsubmitedExpense(allExpenses?.filter((item) => item.state === 'submited' || item.state === 'aproved'));
      setAllsubmitedExpensesForFilters(allExpenses?.filter((item) => item.state === 'submited' || item.state === 'aproved'))


      if (activeTab === "downloadedTab") {

        const downlodedData = allExpenses.filter((item) => item.state == 'aproved' && item.sendToQb == true)
        setAllsubmitedExpense(downlodedData)
        setAllsubmitedExpensesForFilters(downlodedData)
      }
      if (activeTab === "pendingDownloadTab") {
        const pendingDownloadData = allExpenses.filter((item) => item.state == 'aproved' && item.sendToQb == false)

        setAllsubmitedExpense(pendingDownloadData)
        setAllsubmitedExpensesForFilters(pendingDownloadData)
      }
      if (activeTab === "pendingApprovalTab") {
        const pendingApprovalData = allExpenses.filter((item) => item.state == 'submited')
        setAllsubmitedExpense(pendingApprovalData)
        setAllsubmitedExpensesForFilters(pendingApprovalData)
      }
      if (activeTab === "pendingSubmissionTab") {
        const pendingSubmissionData = allExpenses.filter((item) => item.state == 'hold')
        setAllsubmitedExpense(pendingSubmissionData)
        setAllsubmitedExpensesForFilters(pendingSubmissionData)
      }



      setAllExpenses(allExpenses)
    } catch (error) {
      console.log("error from get expense api", error);
    }
  }

  const getAllSubmitedExpenseByApprover = async () => {

    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-expense-for-approver/?approverID=${userID}`,
        config
      );

      const allExpenses = data.expenses.sort((a, b) => new Date(b.date) - new Date(a.date))
      setAllTheEmployee(data.users)

      setAllsubmitedExpense(allExpenses?.filter((item) => item.state === 'submited' || item.state === 'aproved'));
      setAllsubmitedExpensesForFilters(allExpenses?.filter((item) => item.state === 'submited' || item.state === 'aproved'))


      if (activeTab === "downloadedTab") {

        const downlodedData = allExpenses.filter((item) => item.state == 'aproved' && item.sendToQb == true)
        setAllsubmitedExpense(downlodedData)
        setAllsubmitedExpensesForFilters(downlodedData)
      }
      if (activeTab === "pendingDownloadTab") {
        const pendingDownloadData = allExpenses.filter((item) => item.state == 'aproved' && item.sendToQb == false)

        setAllsubmitedExpense(pendingDownloadData)
        setAllsubmitedExpensesForFilters(pendingDownloadData)
      }
      if (activeTab === "pendingApprovalTab") {
        const pendingApprovalData = allExpenses.filter((item) => item.state == 'submited')
        setAllsubmitedExpense(pendingApprovalData)
        setAllsubmitedExpensesForFilters(pendingApprovalData)
      }
      if (activeTab === "pendingSubmissionTab") {
        const pendingSubmissionData = allExpenses.filter((item) => item.state == 'hold')
        setAllsubmitedExpense(pendingSubmissionData)
        setAllsubmitedExpensesForFilters(pendingSubmissionData)
      }



      setAllExpenses(allExpenses)
    } catch (error) {
      console.log("error from get expense api", error);
    }
  }

  useEffect(() => {
    if (adminloginInfo?.token) {
      getAllSubmitedExpense()
    } else {
      getAllSubmitedExpenseByApprover()
    }

  }, [newData])

  const approveSelectedExpenses = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to approve these Expenses",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Approve"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowItems.length < 1) {
            errorNotify("Please select at least 1 Expense.")
          } else {
            const rowIds = []

            selectedRowItems.map((item) => {
              rowIds.push(item._id)
            })

            if (rowIds) {
              const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/approve-selected-expenses`,
                rowIds,
                config
              );
              if (data) {
                setNewData(data);
                successNotify('Expenses has been approved.')
                setSelectedRowItems([])
              }
            }
          }
        } catch (error) {
          console.log("error", error)
          errorNotify("Something went wrong.")
        }
      }
    })
  }

  const getAllUser = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
        config
      );
      allTheEmployee.push(...data.allUsers);
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };

  const getSuperAdmin = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
        config
      );
      allTheEmployee.push(...data.superAdmin);
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };

  const getAllManager = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
        config
      );
      allTheEmployee.push(...data.allManager);
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };

  const getAllSubAdmin = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`,
        config
      );
      allTheEmployee.push(...data.allSubAdmin);
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };

  const submitTimesheet = async (itemID) => {
    handleCloseUserMenu()
    Swal.fire({
      title: "Are you sure?",
      text: "You want to submit this expense",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, submit it!"
    }).then(async (result) => {
      if (result.isConfirmed) {


        try {
          const { data } = await axios.get(`https://app.timetraker.com/backend/api/submit-expense/?expenseID=${itemID}`, config)
          setNewData(data);
          successNotify('Expense has been submited.')
        }
        catch (error) {
          console.log(" error", error)
          errorNotify("Something went wrong.")
        }
      }
    });
  }

  const deleteData = async (itemID) => {
    handleCloseUserMenu()
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this expense",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await axios.delete(`https://app.timetraker.com/backend/api/delete-expense/?expenseID=${itemID}`, config)
          setNewData(data);
          successNotify('Expense has been deleted.')

        } catch (error) {
          console.log(error)
          errorNotify("Something went wrong.")
        }
      }
    })

  }

  const nitifyUser = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to notify employee",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowItems.length < 1) {
            errorNotify("Please select at least 1 Timesheet.")
          } else {
            const { data } = await axios.post(`https://app.timetraker.com/backend/api/nitify-hold-Timesheets`, { selectedRowItems: selectedRowItems }, config)
            successNotify('We have sent the nitification email.')
            setSelectedRowItems([])
          }
        } catch (error) {
          console.log(error)
          errorNotify("Something went wrong.")
        }

      }
    })
  }



  useEffect(() => {
    getAllChartOfAccount()
    getAllProject()
    if (adminloginInfo?.token) {
      getAllUser();
      getAllManager();
      getAllSubAdmin();
      getSuperAdmin();
    }
  }, [])



  const TimesheetAprovedNotfy = () => {
    toast.success('Expense has been approved', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const TimesheetRejectNotfy = () => {
    toast.success('Expense has been rejected', {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  const successNotify = (message) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const errorNotify = (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };



  const initialStats = {
    submit: {
      count: 0,
      amount: 0
    },
    approve: {
      count: 0,
      amount: 0
    },
    hold: {
      count: 0,
      amount: 0
    },
    download: {
      count: 0,
      amount: 0
    },
    pending: {
      count: 0,
      amount: 0
    }
  }

  const [stats, setStats] = useState(initialStats);

  const handleGetAll = async () => {
    let dataArray = allExpenses;

    setStats(initialStats)

    dataArray?.map((data) => {
      console.log("stats2", data)
      if (data.state === "submited") {
        setStats((prevData) => ({
          ...prevData,
          ["submit"]: {
            count: prevData.submit.count + 1,
            amount: prevData.submit.amount + data.amount
          }
        }));
      }
      if (data.state === "aproved") {
        setStats((prevData) => ({
          ...prevData,
          ["approve"]: {
            count: prevData.approve.count + 1,
            amount: prevData.approve.amount + data.amount
          }
        }));
      }
      if (data.state === "hold") {
        setStats((prevData) => ({
          ...prevData,
          ["hold"]: {
            count: prevData.hold.count + 1,
            amount: prevData.hold.amount + data.amount
          }
        }));
      }
      if (data.state === "rejected") {
        setStats((prevData) => ({
          ...prevData,
          ["hold"]: {
            count: prevData.hold.count + 1,
            amount: prevData.hold.amount + data.amount
          }
        }));
      }
      if (data.state === "aproved" && data.sendToQb === true) {
        setStats((prevData) => ({
          ...prevData,
          ["download"]: {
            count: prevData.download.count + 1,
            amount: prevData.download.amount + data.amount
          }
        }));
      }
      if (data.state === "aproved" && data.sendToQb === false) {
        setStats((prevData) => ({
          ...prevData,
          ["pending"]: {
            count: prevData.pending.count + 1,
            amount: prevData.pending.amount + data.amount
          }
        }));
      }

    })

  }

  useEffect(() => {
    handleGetAll()
  }, [allsubmitedExpense])


  /// quickbooks 

  const [dataFormQuick, setDataFormQuick] = useState("");
  const [loading, setLoading] = useState(false)
  const [loadingExpense, setLoadingExpense] = useState(false)


  const getQBTokenFormDataBase = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/get-qb-token?companyID=${companyID}`,
        config
      );
      if (data) {
        refresh_token_Access_token(data)
      }
    } catch (error) {
      setLoading(false)
      console.log("error from getAllBankAccount", error);

      // we have to send the notification if not connected
    }
  }


  const refresh_token_Access_token = async (oldData) => {

    setLoading(true)

    let newDataFormQuick = oldData.existToken.fullToken
    // decryptObjectForQB()
    const token = newDataFormQuick;
    try {
      const { data } = await axios.post(
        `https://app.timetraker.com/backend/refreshAccessToken`,
        { token: token, timetrakerCompanyID: companyID },
        config
      );
      if (data) {

        const access_token = data.result.token.access_token
        const refresh_token = data.result.token.refresh_token
        const id_token = data.result.token.id_token

        newDataFormQuick.token.access_token = access_token
        newDataFormQuick.token.refresh_token = refresh_token
        newDataFormQuick.token.id_token = id_token

        // localStorage.setItem("dataFormQuick", JSON.stringify(newDataFormQuick));
        encryptObjectForQB(newDataFormQuick)

        let refress = false
        saveTokenInBackend(newDataFormQuick, refress)
      }

    } catch (error) {
      // setLoading(false)
      console.log(error.response.data.result)
      if (error.response.data.result = "connectToQb") {
        deleteTokenFormMongo()
      }
    }
  }

  const deleteTokenFormMongo = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/delete-qb-token?companyID=${companyID}`,
        config
      );
      if (data) {
        localStorage.removeItem("dataFormQuick")
        window.location.reload()
      }
    } catch (error) {
      console.log(error)
    }
  }


  const saveTokenInBackend = async (dataFormQuick, refress) => {
    setLoading(true)
    decryptObjectForQB()


    try {
      const { data } = await axios.post(
        `https://app.timetraker.com/backend/api/update-qb-token?companyID=${companyID}`,
        dataFormQuick,
        config
      );
      setLoading(false)
      if (data && refress) {

        window.location.reload()
      }
    } catch (error) {
      setLoading(false)
      console.log("error from getAllBankAccount", error);
    }

  }

  const encryptionKey = 'YourEncryptionKey';

  const encryptObjectForQB = (objectToEncrypt) => {

    try {
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(objectToEncrypt), encryptionKey).toString();
      localStorage.setItem('dataFormQuick', encrypted);
    } catch (error) {
      console.error('Encryption failed:', error.message);
    }
  };

  const decryptObjectForQB = () => {
    const QbDataFromLocal = localStorage.getItem("dataFormQuick");
    if (QbDataFromLocal) {
      try {
        const bytes = CryptoJS.AES.decrypt(QbDataFromLocal, encryptionKey);
        setDataFormQuick(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
      } catch (error) {
        console.error('Decryption failed:', error.message);
      }
    } else {
      console.log('No encrypted data found.');
    }
  };

  async function createSelectedExpense(e) {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to download these Expenses in QuickBooks",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Download"
    }).then(async (result) => {
      if (result.isConfirmed) {

        if (selectedRowItems.length < 1) {
          errorNotify("Please select at least 1 Expense.")
        } else {
          setLoadingExpense(true)
          decryptObjectForQB()
          const token = dataFormQuick;
          try {
            let resp = await axios.post(
              "https://app.timetraker.com/backend/create-selected-Expense-purchase",
              { token: token, timetrakerCompanyID: companyID, selectedRowItems: selectedRowItems },
              config
            );
            setLoadingExpense(false)
            setNewData(resp);
            successNotify('Expenses has been downloded.')
            setSelectedRowItems([])
          } catch (error) {
            setLoadingExpense(false)
            alert("There is some error please connect with QuickBooks and try again");
          }
        }
      }
    })
  }

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    if (selectedRowIds.length < 1) {
      errorNotify("Please select at least 1 Timesheet.")
    } else {
      setOpen2(true);
    }
  };

  const handleClose2 = () => {
    setOpen2(false);
  };


  useEffect(() => {
    if (adminloginInfo?.token) {
      getQBTokenFormDataBase()
    }
  }, [])

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  // edit section

  const [selectProject, setSelectProject] = useState("");
  const [selectExpense, setSelectExpense] = useState("");
  const [selectPaidBy, setSelectPaidBy] = useState("");


  const [open3, setOpen3] = React.useState(false);

  const handleClickOpenEdit = () => {
    setOpen3(true);
  };

  const handleCloseEdit = () => {
    setOpen3(false);
  };

  const [formData, setFormData] = useState({
  });


  const [dataNeedToEdit, setDataNeedToEdit] = useState()


  const handleDailogBoxOpen = async (item) => {
    setDataNeedToEdit({
      _id: item._id,
      description: item.description,
      project: item.project,
      date: item.date,
      amount: item.amount,
      expense: item.expense,
      paidBy: item.paidBy
    })
    setFormData({
      _id: item._id,
      description: item.description,
      project: item.project,
      date: item.date,
      amount: item.amount,
      expense: item.expense,
      paidBy: item.paidBy,
      userID: item?.userID,
      approverID: userID
    })
    setSelectPaidBy(item.paidBy)
    setSelectExpense(item.expense)
    setSelectProject(item.project)
    handleClickOpenEdit()
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      handleCloseEdit();
      if (adminloginInfo?.token) {
        const { data } = await axios.post(
          `https://app.timetraker.com/backend/api/edit-expense-by-admin`,
          formData,
          config
        );

        if (data) {
          setNewData(data)
          successNotify('Your timesheet has been successfully updated.')
        }
      } else {
        const { data } = await axios.post(
          `https://app.timetraker.com/backend/api/edit-expense-by-approver`,
          formData,
          config
        );

        if (data) {
          setNewData(data)
          successNotify('Your timesheet has been successfully updated.')
        }
      }
    } catch (error) {
      console.log("error from post timesheet api", error);
      errorNotify("Something went Wrong.")
    }
  };

  const reDownloadExpense = async () => {

    Swal.fire({
      title: "Are you sure?",
      text: "You want to re-download these Expenses",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          if (selectedRowItems.length < 1) {
            errorNotify("Please select at least 1 Expense.")
          } else {

            const rowIds = []

            selectedRowItems.map((item) => {
              rowIds.push(item._id)
            })

            if (rowIds) {
              const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/re-download-selected-expense`,
                selectedRowItems,
                config
              );
              if (data) {
                setNewData(data);
                successNotify('Expenses has been sent to the approve tab.')
                setSelectedRowItems([])
              }
            }
          }
        } catch (error) {
          console.log("error", error)
          errorNotify("Something went wrong.")
        }
      }
    })
  }



  const [location, setLocation] = useState([])


  const [open4, setOpen4] = React.useState(false);

  const handleClickOpen4 = (updatelocation) => {
    if (!updatelocation?.latitude || !updatelocation?.longitude) {
      errorNotify("This timesheet does not include location details .")
    } else {
      setLocation([
        { lat: updatelocation.latitude, lng: updatelocation.longitude }
      ])
      setOpen4(true);
    }
  };

  const handleClose4 = () => {
    setOpen4(false);
  };


  const [open5, setOpen5] = React.useState(false);

  const handleClickOpen5 = () => {

    if (selectedRowItems.length > 0) {

      const checkLocationAvailable = selectedRowItems.filter(item =>
        item?.location?.latitude &&
        item?.location?.longitude
      );

      if (checkLocationAvailable.length > 0) {
        checkLocationAvailable.map((item) => {
          location.push({ lat: item.location.latitude, lng: item.location.longitude })
        })
        setOpen5(true);
      } else {
        errorNotify("Thes timesheets does not include location details .")
      }
    } else {
      errorNotify("Please select at least 1 Timesheet .")
    }

  };

  const handleClose5 = () => {
    setOpen5(false);
    setLocation([])
  };



  return (
    <AdminLayout>
      {/* Row 1 */}
      {
        loading ?
          <Loder className='loder' />
          :
          loadingExpense ?
            <LoderForExpenseDownload className='loder' />
            :
            console.log("")
      }
      <ToastContainer />

      <div
        className={sideBarStatus ? "content_right_dashboard" : "none"}
        style={
          (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
            ? { display: "none" }
            : loading || loadingExpense ?
              { filter: 'blur(2px)', display: "block", minHeight: "100vh", background: "#f1f1f1" }
              : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
        }
      >
        <Row>
          <div
            className="hamburgar"
            style={sideBarStatus ? { display: "none" } : { display: "block" }}
          >
            <i onClick={handleClick} className="fas fa-bars"></i>
          </div>
          <Col md={12} className="dash-container3">
            <div className="header-top3">
              <div className="dash-header1">
                <h5 style={
                  sideBarStatus == true
                    ? { paddingLeft: "10px", paddingTop: "2px" }
                    : { paddingLeft: "60px", paddingTop: "4px" }
                }>Expenses</h5>
              </div>

              <div className="profile-header2">
                <ProfileComponent />
              </div>
            </div>
          </Col>
        </Row>


        <Row style={{ background: '#F1F1F1' }}>
          <Col md={12}>
            <div className="card-container">

              <div onClick={pendingSubmissionTab} className={activeTab === "pendingSubmissionTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }}  >
                {/* <img src={cardImg4} alt="" /> */}
                <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                  <p className='card-para1'>{stats.hold.count}</p>
                  <div>
                    <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Pending Submission</p>
                    <p style={{ fontSize: "12px" }} >$ {Math.round(stats.hold.amount)}</p>
                  </div>
                </div>
              </div>

              <div onClick={pendingApprovalTab} className={activeTab === "pendingApprovalTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }} >
                {/* <img src={cardImg4} alt="" /> */}
                <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                  <p className='card-para1' >{stats.submit.count}</p>
                  <div>
                    <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Pending approval</p>
                    <p style={{ fontSize: "12px" }} >$ {Math.round(stats.submit.amount)}</p>
                  </div>
                </div>
              </div>

              {adminloginInfo?.token ?
                <div onClick={pendingDownloadTab} className={activeTab === "pendingDownloadTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }} >
                  {/* <img src={cardImg4} alt="" /> */}
                  <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                    <p className='card-para1'>{stats.pending.count}</p>
                    <div>
                      <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Pending download</p>
                      <p style={{ fontSize: "12px" }} >$ {Math.round(stats.pending.amount)}</p>
                    </div>
                  </div>
                </div>
                : null
              }


              {adminloginInfo?.token ?
                <div onClick={downloadedTab} className={activeTab === "downloadedTab" ? "card1 expense_card expense_card_sheet active_tab" : "card1 expense_card expense_card_sheet"} style={{ cursor: "pointer", borderRadius: "0px", width: "150px !important" }}  >
                  {/* <img src={cardImg4} alt="" /> */}
                  <div className='card_content_parent' style={{ flexDirection: "row", width: "100%", gap: "10px" }}>
                    <p className='card-para1'>{stats.download.count}</p>
                    <div>
                      <p className='card-para2' style={{ marginTop: "8px", fontSize: "16px", marginBottom: "0.1rem" }} >Downloaded</p>
                      <p style={{ fontSize: "12px" }} >$ {Math.round(stats.download.amount)}</p>
                    </div>
                  </div>
                </div>
                : null
              }



            </div>
          </Col>
        </Row>


        {/* Row 2 */}
        <div className="adminlayout">
          <div className="mainContainer">

            <div className="admin">
              <div className="aproveFilterBy_parent">
                <div className="text-time ">

                  <Form className="input-menu" >
                    <Form.Select
                      className="input-menu-focus"
                      style={{ cursor: 'pointer' }}
                      name="project"
                      aria-label="Default select example"
                      onChange={handleChangeForFilterForUser}

                    >
                      <option value="">Project</option>
                      {projectInfo &&
                        projectInfo.map((item, index) => {
                          return (
                            <option key={index} value={item.project}>
                              {item.project}
                            </option>
                          );
                        })}
                    </Form.Select>

                    <Form.Select
                      className="input-menu-focus"
                      style={{ cursor: 'pointer' }}
                      name="expense"
                      aria-label="Default select example"
                      onChange={handleChangeForFilterForUser}


                    >
                      <option value="">Type</option>
                      {chartOfAccount &&
                        chartOfAccount.map((item, index) => {
                          return (
                            <option key={index} value={item.task}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>

                    <Form.Select
                      className="input-menu-focus"
                      style={{ cursor: 'pointer' }}
                      name="employee"
                      aria-label="Default select example"
                      onChange={handleChangeForFilterForUser}
                    >
                      <option value="">Employee</option>
                      {allTheEmployee &&
                        allTheEmployee.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>

                    <i style={{ cursor: "pointer", fontSize: "29px", color: "#04542c", alignItems: "center", display: "flex" }} onClick={() => { handleClickOpen() }} class="fa-solid fa-calendar-days"></i>

                  </Form>
                </div>



                {activeTab === "pendingSubmissionTab"
                  ?
                  <div className="button-container">
                    {ExpenseLocationTrack
                      ?
                      <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                      : null}
                    <button onClick={nitifyUser} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Notify Employee</button>
                  </div>
                  :
                  activeTab === "pendingApprovalTab"
                    ?
                    <div className="button-container">
                      {ExpenseLocationTrack
                        ?
                        <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                        : null}
                      <button className='button-container-button all_approve_button' onClick={approveSelectedExpenses} style={{ cursor: "pointer" }}>Approve</button>
                      <button onClick={handleClickOpen2} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Print</button>
                    </div>
                    :
                    activeTab === "pendingDownloadTab"
                      ?
                      <div className="button-container">
                        {ExpenseLocationTrack
                          ?
                          <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                          : null}
                        <button className='button-container-button all_approve_button' onClick={handleClickOpen2} style={{ cursor: "pointer" }}>Print</button>
                        {adminloginInfo?.token ?
                          <button onClick={createSelectedExpense} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Send To QuickBooks</button>
                          : null}
                      </div>
                      :
                      activeTab === "downloadedTab"
                        ?
                        <div className="button-container">
                          {ExpenseLocationTrack
                            ?
                            <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                            : null}
                          <button onClick={handleClickOpen2} className='button-container-button all_approve_button' style={{ cursor: "pointer" }}>Print</button>
                          {adminloginInfo?.token ?
                            <button onClick={reDownloadExpense} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Re-Download</button>
                            : null}
                        </div>
                        :
                        <div className="button-container">
                          {ExpenseLocationTrack
                            ?
                            <LocationOnIcon onClick={handleClickOpen5} style={{ cursor: "pointer", fontSize: "37px", color: "#04542c" }} />
                            : null}
                          <button className='button-container-button all_approve_button' onClick={approveSelectedExpenses} style={{ cursor: "pointer" }}>Approve</button>
                          <button onClick={createSelectedExpense} className='button-container-button all_approve_button sendToQBButton' style={{ cursor: "pointer" }}>Send To QuickBooks</button>
                        </div>
                }
              </div>


              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ overflow: "visible", cursor: "pointer" }}
                  >
                    <TableHead>
                      <TableRow>
                        {(ExpenseLocationTrack ? columnsForAllSubmitedExpenseTableWithLocation : columnsForAllSubmitedExpenseTable).map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              background: "#04542C",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {column.label === "Date" ?
                              <div onClick={handleChangeSort} style={{ display: "flex", cursor: "pointer" }}> <ArrowDropDownCircleIcon style={reversSort ? { transform: "rotate(180deg)", margin: "0px 10px 0px 17px" } : { margin: "0px 10px 0px 17px" }} /> {column.label} </div>
                              : column.label === "Check" ?
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  {column.label}
                                  <Checkbox
                                    sx={{
                                      color: '#76ff03',
                                      '&.Mui-checked': {
                                        color: '#76ff03',
                                      },
                                    }}
                                    onChange={handleChnageAllCheck}
                                  />
                                </div>
                                : column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      style={{ overflow: "hidden", cursor: "pointer" }}
                    >
                      {allsubmitedExpense
                        ? (rowsPerPage > 0 ? allsubmitedExpense.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : allsubmitedExpense)?.map((item, index) => {
                          function shortenString(str) {
                            if (str.length > 10) {
                              return str.substring(0, 10) + '...';
                            }
                            return str;
                          }
                          function shortenString25(str) {
                            if (str.length > 25) {
                              return str.substring(0, 25) + '...';
                            }
                            return str;
                          }
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                              <TableCell style={{ textAlign: 'center' }}>{item?.date}</TableCell>
                              <TableCell title={item?.userName} style={{ textAlign: 'center' }}>{shortenString(item?.userName)}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}>{item?.amount}</TableCell>
                              <TableCell title={item?.paidBy} style={{ textAlign: 'center' }}>{item?.paidBy}</TableCell>
                              <TableCell title={item?.description ? item?.description : "Empty"} style={{ textAlign: 'center' }}>{shortenString(item?.description ? item?.description : "Empty")}</TableCell>
                              <TableCell title={item?.expense} style={{ textAlign: 'center' }}>{shortenString(item?.expense)}</TableCell>
                              <TableCell title={item?.project} style={{ textAlign: 'center' }}>{shortenString(item?.project)}</TableCell>
                              <TableCell style={{ textAlign: 'center' }}><i style={{ color: '#134563', fontSize: '25px' }} onClick={() => (getImage(item?._id))} class="fa-solid fa-paperclip"></i></TableCell>


                              <TableCell align="center">
                                <Checkbox checked={selectedRowItems.includes(item)}
                                  onChange={() => handleCheck(item)}

                                  sx={{
                                    color: blue[800],
                                    '&.Mui-checked': {
                                      color: blue[600],
                                    },
                                  }} />
                              </TableCell>



                              {activeTab === "pendingSubmissionTab" ?
                                <TableCell style={{ textAlign: 'center' }}>
                                  <i
                                    title="Submit"
                                    onClick={() => submitTimesheet(item?._id)}
                                    style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer', marginRight: "10px" }}
                                    className="fa-solid fa-arrow-up-from-bracket userIconsHover"
                                  ></i>
                                  <i
                                    title="Delete"
                                    onClick={() => deleteData(item?._id)}
                                    style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginLeft: "10px" }}
                                    className="fa-sharp fa-solid fa-trash userIconsHover"
                                  ></i>
                                </TableCell>
                                :
                                activeTab === "pendingDownloadTab" ?

                                  <TableCell style={{ textAlign: 'center' }}>
                                    <i
                                      title="Edit"
                                      onClick={() => handleDailogBoxOpen(
                                        item
                                      )

                                      }
                                      style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }}
                                      className="fa-solid fa-pen-to-square userIconsHover"
                                    ></i>
                                    <i
                                      onClick={(event) => (handleOpenUserMenu(event, item?._id, item?.userID))}
                                      class="fa-solid fa-thumbs-down"
                                      title='Reject'
                                      style={{ color: 'red', fontSize: '20px', marginLeft: "10px" }}>
                                    </i>
                                  </TableCell>
                                  :
                                  activeTab === "downloadedTab" ?
                                    <TableCell style={{ textAlign: 'center' }}>
                                      <i
                                        class="fa-solid fa-thumbs-up"
                                        title={item?.state === 'aproved' ? 'null' : 'Approve'}
                                        style={
                                          item?.state === 'aproved' ?
                                            { color: 'gray', fontSize: '20px', marginRight: "10px" }
                                            :
                                            { color: '#73c31d', fontSize: '20px', marginRight: "10px" }
                                        }>
                                      </i>
                                      <i
                                        class="fa-solid fa-thumbs-down"
                                        title={item?.state === 'aproved' ? 'null' : 'Reject'}
                                        style={
                                          item?.state === 'aproved' ?
                                            { color: 'gray', fontSize: '20px', marginleft: "10px" }
                                            :
                                            { color: 'red', fontSize: '20px', marginLeft: "10px" }
                                        }>
                                      </i>
                                    </TableCell>
                                    :
                                    <TableCell style={{ textAlign: 'center' }}>
                                      <i
                                        onClick={() => (item?.state === 'aproved' ? null : aproveExpense(item?._id, item?.userID))}
                                        class="fa-solid fa-thumbs-up"
                                        title={item?.state === 'aproved' ? 'null' : 'Approve'}
                                        style={
                                          item?.state === 'aproved' ?
                                            { color: 'gray', fontSize: '20px', marginRight: "10px" }
                                            :
                                            { color: '#73c31d', fontSize: '20px', marginRight: "10px" }
                                        }>
                                      </i>
                                      <i
                                        onClick={(event) => (item?.state === 'aproved' && item.sendToQb === true ? null : handleOpenUserMenu(event, item?._id, item?.userID))}
                                        class="fa-solid fa-thumbs-down"
                                        title={item?.state === 'aproved' && item.sendToQb === true ? 'null' : 'Reject'}
                                        style={
                                          item?.state === 'aproved' && item.sendToQb === true ?
                                            { color: 'gray', fontSize: '20px', marginleft: "10px" }
                                            :
                                            { color: 'red', fontSize: '20px', marginLeft: "10px" }
                                        }>
                                      </i>
                                    </TableCell>

                              }




                              <Dialog
                                open={open4}
                                maxWidth="sm" fullWidth
                                onClose={handleClose4}
                              >
                                <MapComponent locations={location} />
                              </Dialog>

                              <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                              >
                                <MenuItem className="reject_reson" style={{ flexDirection: "column" }}  >

                                  <p>Please add the reason of rejection.</p>
                                  <textarea onChange={handleChangeRejectReason} />
                                  <button onClick={rejectExpence}>save</button>

                                </MenuItem>
                              </Menu>





                              <TableCell style={{ textAlign: 'center' }} >
                                {item?.state == 'aproved' && item.sendToQb == false ? 'Pending Download' : item?.state == 'aproved' && item.sendToQb == true ? 'Downloaded' : item?.state}
                              </TableCell>

                              {ExpenseLocationTrack
                                ?
                                <TableCell title={item?.location?.address ? item?.location?.address : "null"} onClick={() => handleClickOpen4(item.location)} style={{ textAlign: 'center' }} >
                                  {shortenString25(item?.location?.address ? item?.location?.address : "null")}
                                </TableCell>
                                :
                                null}





                              <Dialog
                                open={openreceipt}
                                onClose={handleCloseRecept}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogTitle id="alert-dialog-title">
                                  {"Receipt Image"}
                                </DialogTitle>
                                <DialogContent>
                                  <img style={{ maxWidth: '100%' }} src={`https://app.timetraker.com/backend/api/photo/${expenseId}`} alt='Expense Image' />

                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleCloseRecept}>Close</Button>

                                </DialogActions>
                              </Dialog>

                            </TableRow>
                          );
                        })
                        : data?.map((item, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell align="center">{item?.name}</TableCell>
                              <TableCell align="center">{item?.email}</TableCell>
                              <TableCell align="center">{item?.phone}</TableCell>
                              <TableCell align="center">{item?.role}</TableCell>
                              <TableCell align="center">
                                {item?.createdAt}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={allsubmitedExpense?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

              </Paper>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
          <div style={{ marginBottom: "40px" }} className="button-container">
            <ExpenseUserPdf userTimesheetInfo={allExpenses} selectedRowIds={selectedRowIds} />
            <ExpenseUserExcel userTimesheetInfo={allExpenses} selectedRowIds={selectedRowIds} />
            <ExpenseUserCsv userTimesheetInfo={allExpenses} selectedRowIds={selectedRowIds} />
          </div>
          <div className="button-container">
            <button className='button-container-button' onClick={handleClose2} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
          </div>
        </div>
      </Dialog>


      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <form style={{ marginTop: "20px", gap: "20px" }} onSubmit={handleSubmitFilterData} className='date_filter_for_cahrt'>
          <label>Start</label>
          <input required onChange={handlechangeFilterDate} name='startDate' type='date' />
          <label>End</label>
          <input required onChange={handlechangeFilterDate} name='endDate' type='date' />
          <button type='submit' className=''>Filter</button>
        </form>
      </Dialog>



      <Dialog
        open={open3}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='expense-edit'
      >
        <div className="expense">
          <form onSubmit={handleSubmit} action="" className="signup-form">
            <div className="form1">
              <div className="mb-3 expense-dropdown1 space_input">
                <label
                  className="lable_bold"
                  style={{ fontSize: "16px" }}
                  htmlFor=""
                >
                  Expense Type
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <Form.Select
                  style={{ cursor: "pointer" }}
                  value={formData.expense}
                  name="expense"
                  onChange={handleChange}
                  type="selact"
                  required
                  className="drop-area1"
                  aria-label="Default select example"
                >
                  <option value="">Select</option>
                  {chartOfAccount &&
                    chartOfAccount.map((item, index) => {
                      return (
                        <option key={index} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>

              <div className="mb-3 space_input">
                <label
                  className="lable_bold"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor=""
                >
                  Expense Date
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <input
                  style={{ cursor: "pointer" }}
                  defaultValue={formData.date}
                  onChange={handleChange}
                  required
                  type="date"
                  name="date"
                  id=""
                  className="expense-date"
                />
              </div>
              <div className="mb-3 space_input">
                <label
                  className="lable_bold"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor=""
                >
                  Amount
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <input
                  value={formData.amount}
                  onChange={handleChange}
                  required
                  type="text"
                  name="amount"
                  id="amount"
                  className="amount"
                />
              </div>
              <div className="mb-3 expense-dropdown4 space_input">
                <label
                  className="lable_bold"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor=""
                >
                  Project
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <Form.Select
                  value={formData.project}
                  style={{ cursor: "pointer" }}
                  onChange={handleChange}
                  name="project"
                  required
                  className="drop-area4"
                  aria-label="Default select example"
                >
                  <option value="">Select</option>
                  {projectInfo &&
                    projectInfo.map((item, index) => {
                      return (
                        <option key={index} value={item.project}>
                          {item.project}
                        </option>
                      );
                    })}
                </Form.Select>
              </div>

            </div>

            <div className="form2">

              <div className="mb-3 expense-dropdown5 space_input">
                <label
                  className="lable_bold"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor=""
                >
                  Paid by
                  <i className="fa-sharp fa-light fa-asterisk"></i>
                </label>
                <Form.Select
                  value={formData.paidBy}
                  style={{ cursor: "pointer" }}
                  onChange={handleChange}
                  name="paidBy"
                  required
                  className="drop-area5"
                  aria-label="Default select example"
                >
                  <option value="">Select</option>
                  <option value="Paid by Me">Paid by Me</option>
                  <option value="Paid by Company">Paid by Company</option>
                </Form.Select>
              </div>

              <div className="space_input">
                <label
                  className="lable_bold"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  htmlFor=""
                >
                  Description
                </label>
                <textarea

                  onChange={handleChange}
                  type="text"
                  name="description"
                  id=""
                  className="text-area"
                >
                  {dataNeedToEdit?.description}
                </textarea>
              </div>

              <div className="expenseBtn expenseBtn_1">
                <button type="submit" className="btn5" style={{ height: '40px' }} >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>


      <Dialog
        open={open5}
        maxWidth="sm" fullWidth
        onClose={handleClose5}
      >
        <MapComponent locations={location} />
      </Dialog>
    </AdminLayout>
  );
};

export default ExpenseAprove;
